import { render, staticRenderFns } from "./me.vue?vue&type=template&id=53cc8ceb&scoped=true"
import script from "./me.vue?vue&type=script&lang=js"
export * from "./me.vue?vue&type=script&lang=js"
import style0 from "./me.vue?vue&type=style&index=0&id=53cc8ceb&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53cc8ceb",
  null
  
)

export default component.exports