<template>
  <div class="contact-page">
    <!-- 头部导航 -->
    <div class="head">
      <div class="head-left flex lan">broord</div>
      <div class="head-right flex">
        <div class="table">
          <div class="tab tabclass" @click="btns('first')">首页</div>
          <div class="tab tabclass" @click="btns('second')">关于我们</div>
          <div class="tab tabclass" @click="btns('third')">服务</div>
          <div class="tab tabclass" @click="btns('fourth')">产品</div>
          <div class="tab tabclass" @click="btns('case')">案例</div>
          <div class="tabs tabclass" @click="btns('contact')">联系我们</div>
        </div>

      </div>
    </div>
    <!-- 关于我们 -->
    <div class="logo">
      <div class="logo-name">联系我们</div>
    </div>
    <div class="logoimg"></div>
    <div class="content">
      <div class="content-tiele flex">联系我们</div>
      <div class="contact-way">
        <div class="ontact-way-top">
          <div class="way-top-box" v-for="item in contactway" :key="item.articleId">
            <img :src="item.pictureUrl" alt="" />
            <div class="title">{{ item.title }}</div>
            <div class="in-detail">
              <p v-html="item.description"></p>
            </div>
          </div>
        </div>
        <!--        <div class="ontact-way-below">
          <div class="way-below-box">
            <div class="box-top">
              <div class="box-top-left">
                <el-input v-model="location" placeholder="Name..."></el-input>
                <el-input v-model="mailbox" placeholder="Email..."></el-input>
                <el-input v-model="phone" placeholder="Subject..."></el-input>
              </div>
              <div class="box-top-right">
                <el-input type="textarea" :rows="10" resize="none" placeholder="Message..." v-model="textarea">

                </el-input>
              </div>
            </div>
            <div class="box-button flex">发送</div>
          </div>
          <div class="way-below-img"></div>
        </div> -->
      </div>
    </div>
    <me></me>
  </div>
</template>

<script>
  import me from './me.vue';
  import {loadMenuArticles} from '@/api/baixin/menu';
  export default {
    components: {
      me
    },
    data() {
      return {
        activeName: 'contact',
        textarea: '',
        location: '',
        /* 地址*/
        mailbox: '',
        /* 邮箱*/
        phone: '',
        /* 电话*/
        contactway: [
          // {
          //   url: require('../assets/imgs/diz.png'),
          //   name: '地址',
          //   text: '广州市竹丝岗二马路41号302室 中山市南朗盈彩美地19栋21卡'
          // },
          // {
          //   url: require('../assets/imgs/dianhua.png'),
          //   name: '电话',
          //   text: '020-3759 1572'
          // },
          // {
          //   url: require('../assets/imgs/youxiang.png'),
          //   name: '邮箱',
          //   text: 'hr@broord.com'
          // }
        ]
      };
    },
    mounted() {
      this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
      })
    },
    created() {
      this.getMenuArticles(6);
    },
    methods: {
      btns(name) {
        if (name == 'first') {
          this.$router.push('/');
        }
        if (name == 'second') {
          this.$router.push('/aboutus');
        }
        if (name == 'third') {
          this.$router.push('/serve');
        }
        if (name == 'fourth') {
          this.$router.push('/cases');
        }
        if (name == 'case') {
          this.$router.push('/product');
        }
      },
      async getMenuArticles(num) {
        const res = await loadMenuArticles(num);
        this.contactway = res.data[0].articleVOS;
        // console.log(res.data,"获得菜单下所有文章");
      }
    }
  };
</script>
<style scoped lang="scss">
  .contact-page {
    width: 100%;
    height: 100%;
    font-family: Source Han Sans CN;

    .head {
      height: 100%;
      background-color: #fff;
      display: flex;

      .head-left {
        width: 26%;
        font-size: 2.8rem;
        font-weight: 700;
        justify-content: flex-end !important;
      }

      .head-right {
        width: 74%;
        height: 4.32rem;
        padding-top: 1rem;
      }
    }

    // logo
    .logo {
      height: 12.13rem;
      background: url('~@/assets/imgs/top2.png') no-repeat;
      background-size: 100% 100%;
      position: relative;

      .logo-name {
        position: absolute;
        left: 21rem;
        top: 3rem;
        font-size: 3.75rem;
        font-weight: 800;
        color: #fff;
      }
    }

    .logoimg {
      width: 100%;
      height: 46rem;
      background: url('~@/assets/imgs/lx.png') no-repeat;
      background-size: 100% 100%;
    }

    // 内容
    .content {
      // height: 45rem;
      background-color: #fff;
      padding: 4rem 21rem;

      .content-tiele {
        font-size: 1.88rem;
        font-weight: 800;
        color: #414141;
        padding: 4rem 0;
      }

      .contact-way {
        // height: 38rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;

        .ontact-way-top {
          height: 40%;
          display: flex;
          justify-content: space-between;

          .way-top-box {
            width: 30%;
            height: 100%;
            // border: 1px solid #ccc;
            font-size: 1.15rem;

            img {
              width: 4.5rem;
              height: 4.5rem;
              display: block;
              margin: 2rem auto;
              margin-bottom: 1rem;
            }

            .title {
              text-align: center;
              font-weight: 800;
              color: #2c2a2a;
            }

            .in-detail {
              width: 70%;
              margin: 2rem auto;
              text-align: center;
            }
          }
        }

        .ontact-way-below {
          height: 19.32rem;
          display: flex;
          justify-content: space-between;

          .way-below-box {
            width: 56%;
            height: 100%;
            position: relative;

            .box-top {
              height: 14rem;
              display: flex;
              justify-content: space-between;

              .box-top-left,
              .box-top-right {
                width: 47%;
              }

              .box-top-left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              }

              .box-top-right {}
            }

            .box-button {
              position: absolute;
              width: 8.38rem;
              height: 3.2rem;
              background: #016975;
              color: #ffffff;
              font-size: 1.5rem;
              right: 0;
              bottom: 0;
            }
          }

          .way-below-img {
            width: 40%;
            height: 100%;
            background-color: red;
            background: url('~@/assets/imgs/lxs.png') no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }

  /* 导航栏字体 */
  .contact-page /deep/.el-tabs__item {
    font-size: 1.12rem !important;
  }

  /* 被选中导航栏字体颜色 */
  .contact-page /deep/ .el-tabs__item.is-active {
    color: #009bad;
  }

  /*被选中导航栏内容背景颜色 */
  .contact-page /deep/ .el-tabs__active-bar {
    background-color: #009bad;
  }

  /* 取消导航栏下长边框 */
  .contact-page /deep/ .el-tabs__nav-wrap::after {
    width: 0%;
  }

  // 输入框
  .contact-page /deep/ .el-input__inner {
    height: 3.5rem;
    background-color: #f7f7f7;
  }

  .contact-page /deep/.el-textarea__inner {
    background-color: #f7f7f7;
  }

  @media (max-width: 1530px) {
    .contact-page .content {
      padding: 0;
    }

    .contact-page .content .contact-way .ontact-way-top .way-top-box .in-detail {
      margin: 1rem auto;
    }

  }
</style>