
import Vue from 'vue'  // 程序入口 环境依赖插件
import App from './App.vue'  // 引入根组件
import router from './router' // 引入路由配置
// import store from '@/store/index'; //引入vuex

Vue.config.productionTip = false  // 关闭生产模式下给出的提示
// Vue.prototype.$store = store;

//引入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import MetaInfo from 'vue-meta-info';
Vue.use(MetaInfo);



// 定义实例
new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount("#app")
