import Vue from 'vue'
import VueRouter from 'vue-router'
import home_page from '../views/home_page.vue'
import aboutus from '../views/aboutus.vue'
import serve from '../views/serve.vue'
import product from '../views/product.vue'
import cases from '../views/cases.vue'
import contact from '../views/contact.vue'
import casesparticulars from '../views/cases-particulars.vue'
import productparticulars from '../views/product-particulars.vue'







Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home_page',
    component: home_page
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: aboutus
  },
  {
    path: '/serve',
    name: 'serve',
    component: serve
  },
  {
    path: '/product',
    name: 'product',
    component: product
  },
  {
    path: '/productparticulars',
    name: 'productparticulars',
    component: productparticulars
  },
  {
    path: '/cases',
    name: 'cases',
    component: cases
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  }
  ,{
    path: '/casesparticulars',
    name: 'casesparticulars',
    component: casesparticulars
  }
]

const router = new VueRouter({
  routes
})

export default router
