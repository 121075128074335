<template>
  <div class="cases-page">
    <!-- 头部导航 -->
    <div class="head">
      <div class="head-left flex lan">broord</div>
      <div class="head-right flex">
        <div class="table">
          <div class="tab tabclass" @click="btns('first')">首页</div>
          <div class="tab tabclass" @click="btns('second')">关于我们</div>
          <div class="tab tabclass" @click="btns('third')">服务</div>
          <div class="tabs tabclass" @click="btns('fourth')">产品</div>
          <div class="tab tabclass" @click="btns('case')">案例</div>
          <div class="tab tabclass" @click="btns('contact')">联系我们</div>
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="logo">
      <div class="logo-name">产品</div>
    </div>
    <!-- 商品 -->
    <div class="product">
      <div class="product-name flex">
        <span>德高工程产品 -</span>
        <span class="lan">PD系列</span>
      </div>
      <div class="product-box">
        <el-tabs v-model="activeNames" @tab-click="handleClicks">
          <el-tab-pane v-for="product in productTypes" :key="product.productTypeId" :name="product.typeName" :label="product.typeName">
            <div class="ALL-box">
              <div class="demo-image">
                <div class="block" v-for="(fit,index) in productData" :key="index">
                  <el-image style="height: 200px" :src="fit.productIcon" @click="btn(fit)"></el-image>
                  <span class="demonstration">{{ fit.productName }}</span>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <me></me>
  </div>
</template>

<script>
  import me from './me.vue';
  import {loadAllProductType} from '@/api/baixin/productType';
  import { loadProductByType,loadProductPictureByType,loadProductById } from '@/api/baixin/product';
  export default {
    components: {
      me
    },
    data() {
      return {
        activeName: 'fourth',
        activeNames: 'on1',
        productTypes: [],
        metadata: [],
        productData: [],
        oneProduct: {}
      };
    },
    mounted() {
      this.getProductTitle();
      this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
      })
    },
    created() {
      this.getProductTitle();
      // this.metadata = GlueForTile;
      // 默认加载第一种类型的所有产品
    },
    methods: {
      btns(name) {
        if (name == 'first') {
          this.$router.push('/');
        }
        if (name == 'second') {
          this.$router.push('/aboutus');
        }
        if (name == 'third') {
          this.$router.push('/serve');
        }
        if (name == 'case') {
          this.$router.push('/product');
        }
        if (name == 'contact') {
          this.$router.push('/contact');
        }
      },
      btn(item) {
        this.$router.push({
          path: '/casesparticulars',
          query: {
            item: item,
          }
        });
      },
      handleClicks(name) {
        switch (name.index) {
          case '0':
            // this.metadata = GlueForTile
            this.getProductPictureByType(8);
            break;
          case '1':
            // this.metadata = JointSealingMaterial
            this.getProductPictureByType(9);
            break;
          case '2':
            // this.metadata = waterproof
            this.getProductPictureByType(10);
            break;
          case '3':
            // this.metadata = mortar
            this.getProductPictureByType(11);
            break;
          case '4':
            // this.metadata = AdhesionAgent
            this.getProductPictureByType(12);
            break;
          case '5':
            // this.metadata = putty
            this.getProductPictureByType(13);
            break;
          default:
        }
      },
      async getProductPictureByType(num){
        const res = await loadProductPictureByType(num);
        this.productData = res.data;
        // console.log(res,"请求某个类型的所有产品id和封面成功");
      },
      async getProductByType(num){
        const res = await loadProductByType(num);
        // console.log(res,"请求某个类型的所有产品成功");
      },
      async getAllProductType(){
        const res = await loadAllProductType();
        this.productTypes = res.data;
        this.activeNames = res.data[0].typeName;
        // console.log(res,"请求产品类型成功");
      },
      async getProductById(num){
        const res = await loadProductById(num);
        this.oneProduct = res.data;
        // console.log(res,"单个产品的请求");
      },
      getProductTitle(){
        this.getAllProductType();
        this.getProductPictureByType(8);
      }
    }
  };
</script>
<style scoped lang="scss">
  .cases-page {
    width: 100%;
    height: 100%;
    font-family: Source Han Sans CN;

    .head {
      height: 100%;
      background-color: #fff;
      display: flex;

      .head-left {
        width: 26%;
        font-size: 2.8rem;
        font-weight: 700;
        justify-content: flex-end !important;
      }

      .head-right {
        width: 74%;
        height: 4.32rem;
        padding-top: 1rem;
      }
    }

    // logo
    .logo {
      height: 12.13rem;
      background: url('~@/assets/imgs/top2.png') no-repeat;
      background-size: 100% 100%;
      position: relative;

      .logo-name {
        position: absolute;
        left: 21rem;
        top: 3rem;
        font-size: 3.75rem;
        font-weight: 800;
        color: #fff;
      }
    }

    // 产品
    .product {
      padding-top: 5rem;
      background-color: #fff;
      font-weight: 800;

      .product-name {
        margin-bottom: 2rem;
        font-size: 1.9rem;
      }

      .product-box {
        .ALL-box {
          padding: 0 24rem;
          display: flex;
          flex-wrap: wrap;
          margin-top: 2rem;
        }
      }

      .viewall {
        height: 10rem;

        .viewall-box {
          width: 9.07rem;
          height: 3.2rem;
          background-color: #016975;
          color: #fff;
          font-size: 1.12rem;
          cursor: pointer;
        }
      }
    }
  }

  /* 导航栏字体 */
  .cases-page /deep/.el-tabs__item {
    font-size: 1.12rem !important;
  }

  /* 被选中导航栏字体颜色 */
  .cases-page /deep/ .el-tabs__item.is-active {
    color: #009bad;
  }

  /*被选中导航栏内容背景颜色 */
  .cases-page /deep/ .el-tabs__active-bar {
    background-color: #009bad;
  }

  /* 取消导航栏下长边框 */
  .cases-page /deep/ .el-tabs__nav-wrap::after {
    width: 0%;
  }


  .cases-page /deep/ .el-carousel__arrow--left {
    position: relative;
    left: 106rem !important;
    top: 89% !important;
  }

  .cases-page /deep/ .el-carousel__arrow--right {
    position: relative;
    left: 108rem !important;
    top: 89% !important;
  }

  .cases-page /deep/ .el-carousel--horizontal {
    width: 100%;
    height: 100%;
  }

  .cases-page /deep/ .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .cases-page /deep/ .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  .cases-page /deep/ .el-carousel__container {
    height: 100%;
  }

  .cases-page /deep/ .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
  }

  .cases-page /deep/ .el-tabs__nav-scroll {
    padding-bottom: 0rem;
  }

  .demo-image {
    display: flex;
    flex-wrap: wrap;

    .block {
      width: 30%;
      height: 17rem;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      background-color: #026976;
      align-items: center;
      cursor: pointer;
      margin: 1rem;
      padding: 1rem 0;
    }

    .demonstration {
      padding: 1rem;
      color: #fff;
    }
  }
</style>