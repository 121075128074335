<template>
  <div id="app"><router-view /></div>
</template>

<script>
  export default {
    data() {
      return {
        screenWidth: document.body.clientWidth
      };
    },
    mounted() {
      const that = this;
      window.onresize = () =>
        (() => {
          window.screenWidth = document.body.clientWidth;
          that.screenWidth = window.screenWidth;
        })();
    },
    watch: {
      screenWidth(val) {
        // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
        if (!this.timer) {
          // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
          this.screenWidth = val;
          this.timer = true;
          const that = this;
          setTimeout(function() {
            that.sjcount(that.screenWidth); //窗口大小变化后执行的方法
            that.reload(); //窗口大小变化后执行的方法
            that.timer = false;
          }, 10);
        }
      }
    },
    created() {},
    methods: {
      reload() {
        this.isAlive = false;
        this.$nextTick(function() {
          this.isAlive = true;
        });
      },
      sjcount(screewidth) {
        if (screewidth >= 1920) {
          this.numeral = 8;
        } else if (screewidth >= 1680) {
          this.numeral = 7;
        } else if (screewidth >= 1600) {
          this.numeral = 5;
        } else if (screewidth >= 1280) {
          this.numeral = 5;
        } else if (screewidth >= 1024) {
          this.numeral = 4;
        }
      }
    }
  };
</script>

<style>
  @import '../static/common.css';

  /*引入公共样式*/
  #app {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* 导航栏 */
  .tab {
    font-size: 1.12rem;
    border-bottom: 2px solid #fff;
    margin: 0 1rem;
  }

  .tabs {
    color: #009bad;
    font-size: 1.12rem;
    border-bottom: 2px solid;
    margin: 0 1rem;
  }

  .table {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabclass {
    padding-bottom: 0.6rem;
    cursor: pointer;
  }

  .mouse {
    cursor: pointer;
  }
</style>