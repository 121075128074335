<template>
  <div class="product-particulars-page">
    <!-- 头部导航 -->
    <div class="head">
      <div class="head-left flex lan">broord</div>
      <div class="head-right flex">
        <div class="table">
          <div class="tab tabclass" @click="btns('first')">首页</div>
          <div class="tab tabclass" @click="btns('second')">关于我们</div>
          <div class="tab tabclass" @click="btns('third')">服务</div>
          <div class="tab tabclass" @click="btns('fourth')">产品</div>
          <div class="tabs tabclass" @click="btns('case')">案例</div>
          <div class="tab tabclass" @click="btns('contact')">联系我们</div>
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="logo">
      <div class="logo-name">案例详情</div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <img v-for="item in journalism" :key="item.picId" :src="item.picUrl" alt="">
    </div>

    <me></me>
  </div>
</template>

<script>
  import me from './me.vue';
  import {loadPictures} from '@/api/baixin/picture';
  export default {
    components: {
      me
    },
    data() {
      return {
        activeName: 'case',
        journalism: []
      };
    },
    mounted() {
      this.getPictures("009");
      this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
      })
    },
    created() {},
    methods: {
      btns(name) {
        if (name == 'first') {
          this.$router.push('/');
        }
        if (name == 'second') {
          this.$router.push('/aboutus');
        }
        if (name == 'third') {
          this.$router.push('/serve');
        }
        if (name == 'fourth') {
          this.$router.push('/cases');
        }
        if (name == 'contact') {
          this.$router.push('/contact');
        }
      },
      async getPictures(num){
        const res = await loadPictures(num);
        this.journalism = res.data;
        console.log(res,"案例图片"); 
      }
    }
  };
</script>
<style scoped lang="scss">
  .product-particulars-page {
    width: 100%;
    height: 100%;

    font-family: Source Han Sans CN;

    .head {
      height: 100%;
      background-color: #fff;
      display: flex;

      .head-left {
        width: 26%;
        font-size: 2.8rem;
        font-weight: 700;
        justify-content: flex-end !important;
      }

      .head-right {
        width: 74%;
        height: 4.32rem;
        padding-top: 1rem;
      }
    }

    // logo
    .logo {
      height: 12.13rem;
      background: url('~@/assets/imgs/top2.png') no-repeat;
      background-size: 100% 100%;
      position: relative;

      .logo-name {
        position: absolute;
        left: 21rem;
        top: 3rem;
        font-size: 3.75rem;
        font-weight: 800;
        color: #fff;
      }
    }

    .content {
      padding: 0 21rem;

      img {
        display: block;
        width: 100%;

      }
    }
  }

  /* 导航栏字体 */
  .product-particulars-page /deep/.el-tabs__item {
    font-size: 1.12rem !important;
  }

  /* 被选中导航栏字体颜色 */
  .product-particulars-page /deep/ .el-tabs__item.is-active {
    color: #009bad;
  }

  /*被选中导航栏内容背景颜色 */
  .product-particulars-page /deep/ .el-tabs__active-bar {
    background-color: #009bad;
  }

  /* 取消导航栏下长边框 */
  .product-particulars-page /deep/ .el-tabs__nav-wrap::after {
    width: 0%;
  }

  @media (max-width: 1530px) {
    .product-particulars-page .content {
      padding: 0 6rem;
    }
  }
</style>