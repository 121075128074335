<template>
  <div class="home-page">
    <!-- 头部导航 -->
    <el-row>
      <el-col :span="24">
        <div class="head">
          <div class="head-left flex lan">broord</div>
          <div class="head-right flex">
            <div class="table">
              <div class="tabs tabclass" @click="btn('first')">首页</div>
              <div class="tab tabclass" @click="btn('second')">关于我们</div>
              <div class="tab tabclass" @click="btn('third')">服务</div>
              <div class="tab tabclass" @click="btn('fourth')">产品</div>
              <div class="tab tabclass" @click="btn('case')">案例</div>
              <div class="tab tabclass" @click="btn('contact')">联系我们</div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <!-- 轮播图 -->
        <div class="block flex">
          <el-carousel :interval="3000" indicator-position="always" id="el-carousel" trigger="click" arrow="always">
            <el-carousel-item v-for="(item,index) in banner" :key="index"><img :src="item.picUrl" /></el-carousel-item>
          </el-carousel>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <!-- 服务支持 -->
        <div class="greet">
          <div class="greet-top flex">
            <span class="lan">欢迎来到 &nbsp</span>
            <span>柏信</span>
          </div>
          <div class="greet-middle flex">
            <div class="greet-middle-box" v-for="(item1,index1) in serve" :key="index1">
              <div class="serve-box"><img :src="item1.pictureUrl" alt="" /></div>
              <div class="serve flex">{{ item1.title }}</div>
              <div class="greet-bottom-box ">
                <p v-html="item1.description"></p>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <!-- 公司简介 -->
        <div class="company flex">
          <div class="company-left">
            <div class="company-name">{{homeProfile.title}}</div>
            <div class="company-symbol"></div>
            <div class="company-facilitator ">
              <span>德高</span>
              <span class="lan">特约</span>
              <span>工程</span>
              <span class="lan">服务商</span>
            </div>
            <!-- <div class="company-charge ">负责销售德高瓷砖胶、防水材料、填缝料及界面剂等PD系列 德高产品.</div> -->
            <div class="company-introduction ">
              <!-- 广东柏信建材有限公司为德高特约工程服务商，负责销售德高瓷砖胶、防水材料、填缝料及界面剂等PD系列德高产品，及为客户提供售后服务及技术支持。 -->
              <p v-html="homeProfile.description"></p>
            </div>
            <div class="company-more flex mouse" @click="btn('contact')">了解更多</div>
          </div>
          <div class="company-right"><img :src="homeProfile.pictureUrl" alt="" /></div>
        </div>
      </el-col>
    </el-row>

    <!-- 商品 -->
    <div class="product">
      <div class="product-name flex">
        <span>德高工程产品 -</span>
        <span class="lan">PD系列</span>
      </div>
      <div class="product-box">
        <el-tabs v-model="activeNames" @tab-click="handleClicks">
          <el-tab-pane v-for="product in productTypes" :key="product.productTypeId" :name="product.typeName" :label="product.typeName">
            <div class="ALL-box">
              <div class="demo-image">
                <div class="block" v-for="(fit,index) in productData" :key="index">
                  <el-image style="height: 200px" :src="fit.productIcon" @click="btnt(fit)"></el-image>
                  <span class="demonstration">{{ fit.productName }}</span>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- <div class="product-box">
        <el-tabs v-model="activeNames" @tab-click="handleClicks">
          <el-tab-pane label="PC瓷砖胶" name="on1">
            <div class="ALL-box">
              <div class="demo-image">
                <div class="block" v-for="(fit,index) in metadata" :key="index">
                  <el-image style="height: 200px" :src="fit.url" @click="btnt(fit)"></el-image>
                  <span class="demonstration">{{ fit.text }}</span>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="PC填缝美缝" name="on2">
            <div class="ALL-box">
              <div class="demo-image">
                <div class="block" v-for="(fit,index) in metadata" :key="index">
                  <el-image style="height: 200px" :src="fit.url" @click="btnt(fit)"></el-image>
                  <span class="demonstration">{{ fit.text }}</span>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="PC防水" name="on3">
            <div class="ALL-box">
              <div class="demo-image">
                <div class="block " v-for="(fit,index) in metadata" :key="index">
                  <el-image style="height: 200px" :src="fit.url" @click="btnt(fit)"></el-image>
                  <span class="demonstration">{{ fit.text }}</span>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="PC砂浆" name="on4">
            <div class="ALL-box">
              <div class="demo-image">
                <div class="block " v-for="(fit,index) in metadata" :key="index">
                  <el-image style="height: 200px" :src="fit.url" @click="btnt(fit)"></el-image>
                  <span class="demonstration">{{ fit.text }}</span>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="PC界面剂" name="on5">
            <div class="ALL-box">
              <div class="demo-image">
                <div class="block " v-for="(fit,index)  in metadata" :key="index">
                  <el-image style="height: 200px" :src="fit.url" @click="btnt(fit)"></el-image>
                  <span class="demonstration">{{ fit.text }}</span>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="PD腻子" name="on6">
            <div class="ALL-box">
              <div class="demo-image">
                <div class="block " v-for="(fit,index) in metadata" :key="index">
                  <el-image style="height: 200px" :src="fit.url" @click="btnt(fit)"></el-image>
                  <span class="demonstration">{{ fit.text }}</span>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div> -->
    </div>
    <!-- 服务内容 -->
    <div class="serve-content">
      <div class="serve-content-name flex">
        <span class="lan">我们的服务&nbsp&nbsp</span>
        <span>内容</span>
      </div>
      <div class="content ">
        <div v-for="(item2, index2) in servicecontent" :key="index2" @click="btns(index2)" :class="current == index2 ? 'content-box mouse' : 'content-boxs mouse'">
          <div class="content-top">
            <div class="content-logo flex"><img :src="item2.pictureUrl" alt="" /></div>
            <div class="content-name chao1">{{ item2.title }}</div>
          </div>
          <div class="content-text">
            <div class="huitext chao1">
              <p v-html="item2.description"></p>
              <!-- {{ item2.one }} -->
            </div>
            <!-- <div class="huitext chao1">{{ item2.two }}</div>
            <div class="huitext chao1">{{ item2.three }}</div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 统计 -->
    <div class="statistics flex">
      <div class="statistics-box " v-for="(item3,index3) in statisticss" :key="index3">
        <div class="statistics-box-top">{{ item3.title }}</div>
        <div class="statistics-box-below">{{ item3.subtitle }}</div>
      </div>
    </div>

    <el-row>
      <el-col :span="24">
        <!-- 团队 -->
        <div class="group ">
          <div class="group-name flex ">
            <span>我们的&nbsp&nbsp</span>
            <span class="lan">服务团队</span>
          </div>
          <div class="content">
            <div class="content-box " v-for="(item6,index6) in groups" :key="index6">
              <img :src="item6.pictureUrl" alt="" />
              <div class="advanced">高级工程师</div>
              <div class="name">{{ item6.title }}</div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <!-- 优秀品质 -->
    <div class="merit">
      <div class="merit-left"><img :src="excellence.picUrl" /></div>
      <div class="merit-right flex">
        <div class="content">
          <div class="content-box" v-for="(item4,index4) in quality" :key="index4">
            <div class="content-left flex"><img :src="item4.pictureUrl" alt="" /></div>
            <div class="content-right">
              <div class="content-right-top">{{ item4.title }}</div>
              <!-- <div class="content-right-below chao2">{{ item4.text }}</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="cooperation">
      <img :src="cooperativepartner.picUrl" />
    </div>
    <!-- 新闻 -->
    <div class="journalism">
      <div class="journalism-name flex">
        <span>最新&nbsp&nbsp</span>
        <span class="lan">新闻</span>
      </div>
      <div class="content">
        <div class="content-box " v-for="(item5,index5) in hotnews" :key="index5">
          <img :src="item5.pictureUrl" />
          <!-- <div class="times">{{ item5.time }}</div> -->
          <div class="title">
            <p v-html="item5.description"></p>
          </div>
        </div>
      </div>
      <div class="more flex" @click="btn('case')">VIEW ALL</div>
    </div>
    <!-- 我 -->
    <me></me>
  </div>
</template>

<script>
  import me from './me.vue';
  import {loadPictures} from '@/api/baixin/picture';
  import {loadMenuArticles} from '@/api/baixin/menu';
  import {loadAllProductType} from '@/api/baixin/productType';
  import { loadProductByType,loadProductPictureByType,loadProductById } from '@/api/baixin/product';
  export default {
    metaInfo() {
      return {
        title: this.metaName,
        meta: [
          { charset: "utf-8"},
          {name: "keywords",content: this.metaKeywords},
          {name: "description",content: this.metaDecs}
        ]
      }
    },
    components: {
      me
    },
    data() {
      return {
        metaName: "柏信官网",
        metaKeywords: "baixin",
        metaDecs: "介绍各种装修产品",
        productTypes: [],
        productData: [],
        oneProduct: {},
        metadata: [],
        bannerHeight: 916,
        screenWidth: 1920,
        excellence: '',
        cooperativepartner: '',
        banner: [],
        groups: [],
        activeName: 'first',
        current: 0,
        activeNames: 'on1',
        hotnews: [],
        quality: [],
        statisticss: [],
        serve: [],
        homeProfile: {},
        servicecontent: []
      };
    },
    mounted() {
      this.getPictures();
      this.menuAllArticle();
      this.getProductTitle();
      this.setSize1();
      const that = this; //监听浏览器窗口大小改变
      window.addEventListener(
        'resize',
        function() {
          var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
          that.screenWidth = width;
          that.setSize();
        },
        false
      );
      this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
      })
    },
    created() {
      // this.metadata = GlueForTile;
      this.getProductTitle();
    },
    methods: {
      btn(name) {
        if (name == 'second') {
          this.$router.push('/aboutus');
        }
        if (name == 'third') {
          this.$router.push('/serve');
        }
        if (name == 'case') {
          this.$router.push('/product');
        }
        if (name == 'fourth') {
          this.$router.push('/cases');
        }
        if (name == 'contact') {
          this.$router.push('/contact');
        }
      },
      handleClicks(name) {
        switch (name.index) {
          case '0':
            // this.metadata = GlueForTile
            this.getProductPictureByType(8);
            break;
          case '1':
            // this.metadata = JointSealingMaterial
            this.getProductPictureByType(9);
            break;
          case '2':
            // this.metadata = waterproof
            this.getProductPictureByType(10);
            break;
          case '3':
            // this.metadata = mortar
            this.getProductPictureByType(11);
            break;
          case '4':
            // this.metadata = AdhesionAgent
            this.getProductPictureByType(12);
            break;
          case '5':
            // this.metadata = putty
            this.getProductPictureByType(13);
            break;
          default:
        }
      },
      btns(index) {
        this.current = index;
      },
      btnt(item) {
        this.$router.push({
          path: '/casesparticulars',
          query: {
            item: item,
          }
        });
      },
      setSize1() {
        var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.screenWidth = width;
        //图片                高 / 宽  700 / 1920
        this.bannerHeight = (982 / 1920) * this.screenWidth - 50;
        document.getElementById('el-carousel').style.height = this.bannerHeight + 'px';
      },
      setSize() {
        this.bannerHeight = (982 / 1920) * this.screenWidth - 50;
        document.getElementById('el-carousel').style.height = this.bannerHeight + 'px';
      },
      async pictureBanner(num){
        const res = await loadPictures(num);
        this.banner = res.data;
        // console.log(res,'轮播图请求成功'); 
      },
      async picturePartners(num){
        const res = await loadPictures(num);
        this.cooperativepartner = res.data[0];
        // console.log(res,'合作伙伴图请求成功'); 
      },
      async pictureExcellence(num){
        const res = await loadPictures(num);
        this.excellence = res.data[0];
        // console.log(res,"追求卓越图请求成功");
      },
      async getProductPictureByType(num){
        const res = await loadProductPictureByType(num);
        this.productData = res.data;
        // console.log(res,"请求某个类型的所有产品id和封面成功");
      },
      async getProductByType(num){
        const res = await loadProductByType(num);
        // console.log(res,"请求某个类型的所有产品成功");
      },
      async getAllProductType(){
        const res = await loadAllProductType();
        this.productTypes = res.data;
        this.activeNames = res.data[0].typeName;
        // console.log(res,"请求产品类型成功");
      },
      async getProductById(num){
        const res = await loadProductById(num);
        this.oneProduct = res.data;
        // console.log(res,"单个产品的请求");
      },
      async menuAllArticle(){
        const res = await loadMenuArticles(1);
        this.serve = res.data[0].articleVOS;
        this.homeProfile = res.data[1].articleVOS[0];
        this.servicecontent = res.data[2].articleVOS;
        this.statisticss = res.data[3].articleVOS;
        this.groups = res.data[4].articleVOS;
        this.quality = res.data[5].articleVOS;
        this.hotnews = res.data[6].articleVOS;
        // console.log(res,"首页菜单下的文章");
      },
      getPictures(){
        this.pictureBanner("001");
        this.picturePartners("003");
        this.pictureExcellence("008");
      },
      getProductTitle(){
        this.getAllProductType();
        this.getProductPictureByType(8);
      }
    }
  };
</script>
<style scoped lang="scss">
  .home-page {
    width: 100%;
    height: 100%;
    font-family: Source Han Sans CN;

    .head {
      height: 100%;
      background-color: #fff;
      display: flex;

      .head-left {
        width: 26%;
        font-size: 2.8rem;
        font-weight: 700;
        justify-content: flex-end !important;
      }

      .head-right {
        width: 74%;
        height: 4.32rem;
        padding-top: 1rem;
      }
    }

    // 轮播图
    .block {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        display: inline-block;
        // height: auto;
        max-width: 100%;
      }
    }

    // 服务支持
    .greet {
      width: 100%;
      font-weight: 700;

      .greet-top {
        font-size: 1.6rem;
        font-size: 700;
        height: 8rem;
      }

      .greet-middle {
        width: 100%;

        .greet-middle-box {
          padding: 1rem 2rem;

          .serve-box {
            width: 100%;
            height: 6.5rem;

            img {
              display: block;
              width: 6.2rem;
              height: 6.2rem;
              margin: 0 auto;
              border-radius: 50%;
            }
          }

          .serve {
            height: 4rem;
            font-size: 1.3rem;
          }

          .greet-bottom-box {
            width: 20rem;
            height: 6rem;
            padding: 0 1rem;
            text-align: center;
            line-height: 2rem;
            color: #b5b1b2;
          }
        }
      }
    }

    // 公司介绍
    .company {
      height: 39.44rem;
      background-color: #f7f7f7;

      .company-left {
        width: 31rem;
        height: 28rem;
        position: relative;

        // padding-right: 4rem;
        .company-name {
          // line-height: 2rem;
          font-size: 1.12rem;
        }

        .company-symbol {
          width: 1.89rem;
          height: 0.38rem;
          background-color: #034387;
          margin-top: 1rem;
        }

        .company-facilitator {
          font-size: 3.37rem;
          margin: 1.75rem 0 3rem 0;
        }

        .company-charge {
          font-size: 1.13rem;
          color: #888f9a;
          font-style: italic;
        }

        .company-introduction {
          margin: 2rem 0;
          font-size: 1.125rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #99A1AD;
          line-height: 1.5rem;
        }

        .company-more {
          width: 10.2rem;
          height: 2.94rem;
          background-color: #009bad;
          color: #fff;
          position: absolute;
          left: 0;
          button: 0;
          // margin-top: 3rem;
          // cursor: pointer;
        }
      }

      .company-right {
        width: 30rem;
        height: 28rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }



    // 服务内容
    .serve-content {
      height: 52.75rem;
      background-color: #f7f7f7;

      .serve-content-name {
        font-weight: 800;
        font-size: 1.9rem;
        height: 24%;
      }

      .content {
        width: 72%;
        height: 62%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        .content-box,
        .content-boxs {
          width: 30%;
          height: 10.2rem;
          padding: 1rem;

          .content-top {
            height: 40%;
            display: flex;
            margin-bottom: 1rem;

            .content-logo {
              width: 21%;
              height: 100%;
            }

            .content-name {
              width: 70%;
              height: 100%;
              font-size: 1.26rem;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #272727;
              line-height: 4rem;
            }
          }

          .content-text {
            .huitext {
              font-size: 0.88rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #8b8b8b !important;
              margin: 0.5rem 2rem;
            }
          }
        }

        .content-box {
          background: #ffffff;
          box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.06);
          border-radius: 10px;
        }
      }
    }

    // 统计
    .statistics {
      height: 17.07rem;
      background-color: #016975;
      display: flex;

      .statistics-box {
        width: 13rem;
        height: 8rem;
        margin: 0 2rem;

        .statistics-box-top,
        .statistics-box-below {
          font-size: 3.7rem;
          font-family: Arial;
          font-weight: 900;
          color: #ffffff;
          text-align: center;
        }

        .statistics-box-below {
          font-size: 1.52rem;
        }
      }
    }

    // 团队
    .group {
      height: 43.8rem;
      background-color: #fff;

      .group-name {
        height: 23%;
        font-size: 1.9rem;
        font-family: Source Han Sans CN;
        font-weight: 800;
        color: #363636;
      }

      .content {
        width: 64%;
        height: 62%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        display: flex;

        .content-box {
          width: 28%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .advanced {
          position: absolute;
          left: 1rem;
          bottom: 3rem;
          font-size: 0.88rem;
          color: #044386;
        }

        .name {
          position: absolute;
          left: 1rem;
          bottom: 1rem;
          font-size: 1rem;
        }
      }
    }

    // 优秀品质
    .merit {
      height: 39.94rem;
      display: flex;

      .merit-left,
      .merit-right {
        width: 50%;
        height: 100%;

        img {
          width: 100%;
        }

        .content {
          width: 44rem;
          height: 26rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .content-box {
            width: 100%;
            height: 28%;
            display: flex;

            .content-left {
              width: 24%;
              height: 100%;

              img {
                display: block;
                width: 6.19rem;
                height: 6.19rem;
                border-radius: 50%;
              }
            }

            .content-right {
              width: 76%;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .content-right-top,
              .content-right-below {
                // height: 60%;
              }

              .content-right-top {
                font-size: 1.5rem;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #1a1a1a;
              }

              .content-right-below {
                height: 40%;
                color: #a79d97;
                line-height: 1.5rem;
              }
            }
          }
        }
      }
    }

    // 合作伙伴
    .cooperation {
      width: 100%;
      height: 49.564rem;

      // background: url('~@/assets/imgs/hb.png') no-repeat;
      // background-size: 100% 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }

    // 新闻
    .journalism {
      height: 43.25rem;
      background-color: #f7f7f7;

      .journalism-name {
        height: 23%;
        font-size: 1.9rem;
        font-family: Source Han Sans CN;
        font-weight: 800;
      }

      .content {
        width: 60%;
        height: 53%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .content-box {
          width: 22.5rem;
          height: 22.88rem;
          background-color: #fff;

          img {
            width: 100%;
            height: 15.19rem;
            display: block;
          }

          .times {
            color: #044386;
            font-size: 1rem;
            margin: 1rem 2rem;
          }

          .title {
            font-size: 1.12rem;
            color: #333333;
            margin: 0rem 2rem;
          }
        }
      }

      .more {
        width: 10.19rem;
        height: 2.94rem;
        background-color: #009bad;
        color: #fff;
        margin: 0 auto;
        margin-top: 4rem;
        cursor: pointer;
        font-size: 1.12rem;
      }
    }
  }

  // 轮播图
  .home-page /deep/ .block .el-carou sel__item h3 {
    height: 100%;
    color: #475669;
    font-size: 1.15rem;
    opacity: 0.75;
    // line-height: 300px;

    margin: 0;
  }

  .home-page /deep/ .el-carousel__arrow {
    width: 3rem;
    height: 3rem;
    border: 1px solid #fff;
  }

  .home-page /deep/ .el-carousel__arrow--left {
    // position: absolute;
    // top: 54rem;
    // left: 105rem;
  }

  .home-page /deep/ .el-carousel__arrow--right {
    // position: absolute;
    // top: 54rem;
    // left: 109rem;
  }

  .home-page /deep/ .el-carousel--horizontal {
    width: 100%;
    height: 100%;
  }

  .home-page /deep/ .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .home-page /deep/.el-carousel {
    width: 100%;
  }

  .home-page /deep/ .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  .home-page /deep/ .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
  }

  .home-page /deep/ .el-tabs__nav-scroll {
    padding-bottom: 0rem;
  }

  .home-page /deep/.el-carousel__container {
    height: 100% !important;
  }

  /* 导航栏字体 */
  .home-page /deep/.el-tabs__item {
    font-size: 1.12rem !important;
  }

  /* 被选中导航栏字体颜色 */
  .home-page /deep/ .el-tabs__item.is-active {
    color: #009bad;
  }

  /*被选中导航栏内容背景颜色 */
  .home-page /deep/ .el-tabs__active-bar {
    background-color: #009bad;
  }

  /* 取消导航栏下长边框 */
  .home-page /deep/ .el-tabs__nav-wrap::after {
    width: 0%;
  }

  .demo-image {
    display: flex;
    flex-wrap: wrap;

    .block {
      width: 30%;
      height: 17rem;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      background-color: #026976;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;
      margin: 1rem;
      padding: 1rem 0;
    }

    .demonstration {
      padding: 1rem;
      color: #fff;
    }
  }

  // 产品
  .product {
    padding-top: 5rem;
    background-color: #fff;
    font-weight: 800;

    .product-name {
      margin-bottom: 2rem;
      font-size: 1.9rem;
    }

    .product-box {
      .ALL-box {
        padding: 0 24rem;
        display: flex;
        flex-wrap: wrap;
        margin-top: 2rem;
      }
    }

    .viewall {
      height: 10rem;

      .viewall-box {
        width: 9.07rem;
        height: 3.2rem;
        background-color: #016975;
        color: #fff;
        font-size: 1.12rem;
        cursor: pointer;
      }
    }
  }
</style>