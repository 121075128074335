<template>
  <div class="me-page flex">
    <div class="me-box ">
      <div class="title">
        <div class="attention" v-for="item in titles">{{ item.tag }}</div>
      </div>
      <div class="content">
        <div class="content-left">
          <div class="content-left-top" v-for="item3 in articles">
            <p v-html="item3.description"></p>
          </div>
          <!-- 广东柏信建材有限公司为德高特约工程服务商，负责销售德高瓷砖胶、防水材料、填缝料及界面剂等PD系列德高产品，及为客户提供售后服务及技术支持。 -->
          <!-- <div class="content-left-below">柏信建材目前供货与服务于万科地产、越秀地产、龙光地产、金地地产、宏鼎地产等百强地产商。</div> -->
        </div>
        <div class="content-right ">
          <div class="content-right-box" v-for="item1 in attentions"><img :src="item1.picUrl" alt="" /></div>
        </div>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <div class="bottom-left-text" v-for="item2 in finish">{{ item2.tag }}</div>
        </div>
        <div class="bottom-right">{{bottomConstant.filingNumber}}：{{bottomConstant.broord}}</div>
      </div>
      <div class="btm_footer">
        <div class="content">
            <p>{{filings.englishName}}</p>
            <p v-html="filings.description"></p>
            <!-- <p><a href="http://beian.miit.gov.cn">{{filings.subtitle}}</a></p> -->
            <a style="color:#FFF" href="https://beian.miit.gov.cn/" target="_blank">{{filings.subtitle}}</a>
            <!-- <a href="http://beian.miit.gov.cn">{{filings.subtitle}}</a> -->
        </div>
    </div>
    </div>
  </div>
</template>
<script>
  import {loadHomeSetting, loadTreeMenu} from '@/api/baixin/menu';
  import {loadPictures} from '@/api/baixin/picture';
  import {loadTypeArticle} from '@/api/baixin/article';
  export default {
    data() {
      return {
        finish: [],
        attentions: [],
        bottomConstant:{},
        titles: [],
        articles: {},
        filings: {}
      };
    },
    mounted() {
      this.getBottom();
      this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
      })
    },
    created() {},
    methods: {
      async getMenuById(){
        let num = 7;
        const res = await loadTreeMenu(num);
        this.finish = res.data;
        // console.log(res,"7请求成功");
      },
      async getBottomMenu(){
        let num = 8;
        const res = await loadTreeMenu(num);
        this.titles = res.data;
        // console.log(res,"8请求成功");
      },
      async getHomeSetting(){
        const res = await loadHomeSetting();
        this.bottomConstant = res.data;
        // console.log(res,"常量请求成功");
      },
      async getPictures(){
        let num = "004";
        const res = await loadPictures(num);
        this.attentions = res.data;
        // console.log(res,"关于我们请求成功");
      },
      async getTypeArticle(){
        let num = 14;
        const res = await loadTypeArticle(num);
        this.articles = res.data;
        // console.log(res.data,"获得关于我们公司的文章");
      },
      async getFilings(){
        let num = 22;
        const res = await loadTypeArticle(num);
        this.filings = res.data[0];
        // console.log(res.data,"获得备案");
      },
      getBottom(){
        this.getBottomMenu();
        this.getMenuById();
        this.getHomeSetting();
        this.getTypeArticle();
        this.getPictures();
        this.getFilings();
      }
    }
  };
</script>

<style scoped lang="scss">
  .me-page {
    height: 610px;
    background-color: #004D56;
    font-size: 18px;
    
    color: #fff;

    .me-box {
      width: 76rem;
      height: 23rem;

      .title {
        width: 100%;
        height: 4rem;
        display: flex;

        .attention {
          width: 33%;
        }
      }

      .content {
        width: 100%;
        height: 74%;
        display: flex;
        justify-content: space-between;
        border-bottom: 0.1rem solid #fff;

        .content-left,
        .content-right {
          width: 36%;
          height: 100%;
          font-size: 14px;

          .content-left-top,
          .content-left-below {
            width: 18rem;
          }

          .content-left-below {
            margin-top: 2rem;
          }

          .content-right-box {
            width: 50px;
            height: 50px;
            margin: 0 0.5rem;

            img {
              width: 100%;
            }
          }
        }

        .content-right {
          display: flex;
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;

        .bottom-left,
        .bottom-right {
          width: 48%;
          height: 2rem;
          display: flex;

          .bottom-left-text {
            width: 20%;
            height: 100%;
          }
        }

        .bottom-right {
          display: flex;
          justify-content: flex-end;
        }
      }
      .btm_footer {
        background-color: #004D56;

        .content {
          color: #fff;
          font-size: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 27px;
          padding-bottom: 27px;
          // padding: 20px;
        }
      }
      
    }
    
  }
</style>