<template>
  <div class="product-page">
    <!-- 头部导航 -->
    <div class="head">
      <div class="head-left flex lan">broord</div>
      <div class="head-right flex">
        <div class="table">
          <div class="tab tabclass" @click="btns('first')">首页</div>
          <div class="tab tabclass" @click="btns('second')">关于我们</div>
          <div class="tab tabclass" @click="btns('third')">服务</div>
          <div class="tab tabclass" @click="btns('fourth')">产品</div>
          <div class="tabs tabclass" @click="btns('case')">案例</div>
          <div class="tab tabclass" @click="btns('contact')">联系我们</div>
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="logo">
      <div class="logo-name">案例</div>
    </div>

    <el-row>
      <!-- 内容 -->
      <div class="content">
        <el-col :span="12">
          <div class="content-left" @click="btn">
            <div class="left-box" v-for="item in cases" :key="item.articleId">
              <div class="box-img"><img :src="item.pictureUrl" alt="" /></div>
              <div class="box-text">
                <div class="company-name">{{item.englishName}}</div>
                <div class="company-facilitator">{{item.title}}</div>
                <div class="company-charge ">
                  <p v-html="item.description"></p>
                </div>
                <div class="company-more">{{item.subtitle}}</div>
                <div class="logos"></div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="11">
          <div class="content-right">
            <!-- <div style="height: 3rem;">
              <el-input placeholder="请输入内容" v-model="input1" class="input-with-select"><el-button slot="append" icon="el-icon-search"></el-button></el-input>
            </div> -->
            <!-- 最新产品资讯 -->
            <div class="content-ong">
              <div class="title">
                <div class="title-img"></div>
                <div>最新产品资讯</div>
              </div>
              <div class="content-box" v-for="item1 in information" :key="item1.articleId">
                <img :src="item1.pictureUrl" alt="" />
                <div class="content-text">
                  <div class="content-text-top">
                    <p v-html="item1.description"></p>
                  </div>
                  <div class="content-text-below">{{ item1.subtitle }}</div>
                </div>
              </div>
            </div>
            <div class="content-two">
              <div class="title">
                <div class="title-img"></div>
                <div>最新产品资讯</div>
              </div>
              <div class="content-text" v-for="item2 in explain" :key="item2.articleId">
                <p v-html="item2.description"></p>
              </div>
            </div>
            <div class="content-three">
              <div class="title">
                <div class="title-img"></div>
                <div>友情链接</div>
              </div>
              <div class="content-text" v-for="item3 in friendship" :key="item3.articleId">
                {{ item3.title }}
              </div>
            </div>
          </div>
        </el-col>
      </div>
    </el-row>

    <!-- 分页 -->
    <!-- <div class="paging"><el-pagination background layout="prev, pager, next" :total="50"></el-pagination></div> -->
    <me></me>

  </div>
</template>

<script>
  import me from './me.vue';
  import {loadMenuArticles} from '@/api/baixin/menu';
  export default {
    components: {
      me
    },
    data() {
      return {
        activeName: 'case',
        input1: '',
        friendship: [],
        explain: [],
        information: [],
        hand: [{
            text: '让施工人员充分了解材料特性，区分材料使用'
          },
          {
            text: '演示材料的施工方法'
          },
          {
            text: '讲解施工过程中的注意事项'
          }
        ],
        cases: [],
        spreading: [
          // {
          //   url: require('../assets/imgs/t1.png')
          // },
          // {
          //   url: require('../assets/imgs/t2.png')
          // },
          // {
          //   url: require('../assets/imgs/t3.png')
          // },
          // {
          //   url: require('../assets/imgs/t4.png')
          // },
          // {
          //   url: require('../assets/imgs/t5.png')
          // }
        ]
      };
    },
    mounted() {
      this.getMenuArticle(5);
      this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
      })
    },
    created() {},
    methods: {
      btns(name) {
        if (name == 'first') {
          this.$router.push('/');
        }
        if (name == 'second') {
          this.$router.push('/aboutus');
        }
        if (name == 'third') {
          this.$router.push('/serve');
        }
        if (name == 'fourth') {
          this.$router.push('/cases');
        }
        if (name == 'contact') {
          this.$router.push('/contact');
        }
      },
      btn() {
        this.$router.push('/productparticulars');
      },
      async getMenuArticle(num){
        const res = await loadMenuArticles(num);
        this.cases = res.data[0].articleVOS;
        this.information= res.data[1].articleVOS;
        this.explain = res.data[2].articleVOS;
        this.friendship = res.data[3].articleVOS;
        // console.log(res,"案例所有文章");
      }
    }
  };
</script>
<style scoped lang="scss">
  .product-page {
    width: 100%;
    height: 100%;

    font-family: Source Han Sans CN;

    .head {
      height: 100%;
      background-color: #fff;
      display: flex;

      .head-left {
        width: 26%;
        font-size: 2.8rem;
        font-weight: 700;
        justify-content: flex-end !important;
      }

      .head-right {
        width: 74%;
        height: 4.32rem;
        padding-top: 1rem;
      }
    }

    // logo
    .logo {
      height: 12.13rem;
      background: url('~@/assets/imgs/top2.png') no-repeat;
      background-size: 100% 100%;
      position: relative;

      .logo-name {
        position: absolute;
        left: 21rem;
        top: 3rem;
        font-size: 3.75rem;
        font-weight: 800;
        color: #fff;
      }
    }

    // 内容
    .content {
      width: 64.5%;
      height: 100%;
      margin: 0 auto;
      padding: 5rem 0 0 0;
      display: flex;
      justify-content: space-between;

      .content-left {

        cursor: pointer;

        .left-box {
          width: 100%;
          height: 42.82rem;
          background: #f7f7f7;
          border-radius: 5px;
          margin-bottom: 5rem;

          .box-img {
            height: 60%;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .box-text {
            height: 13rem;
            padding: 2rem;
            position: relative;

            .company-name {
              margin-bottom: 1rem;
              font-size: 1.5rem;
              font-weight: 900;
              color: #004084;
            }

            .company-symbol {
              width: 1.89rem;
              height: 0.38rem;
              background-color: #034387;
              margin-top: 1rem;
            }

            .company-facilitator {
              font-size: 1.88rem;
              font-weight: 800;
            }

            .company-charge {
              width: 68%;
              font-size: 1.13rem;
              color: #888f9a;
              margin: 2rem 0;
            }

            .company-introduction {
              margin: 3em 0;
            }

            .company-more {
              font-weight: 900;
              color: #034387;
              cursor: pointer;
              font-size: 1.15rem;
            }

            .logos {
              width: 7.94rem;
              height: 8.15rem;
              position: absolute;
              right: 0;
              bottom: 0;
              background: url('~@/assets/imgs/you.png') no-repeat;
              background-size: 100% 100%;
              cursor: pointer;
            }
          }
        }
      }

      .content-right {
        width: 25.32rem;
        height: 100%;

        .content-ong {
          height: 23.52rem;
          margin-top: 1rem;

          .title {
            display: flex;
            align-items: center;
            font-weight: 800;
            color: #131722;
            font-size: 1.15rem;

            .title-img {
              width: 1rem;
              height: 1rem;
              display: block;
              background: url('~@/assets/imgs/yous.png') no-repeat;
              background-size: 100% 100%;
              margin-right: 1rem;
            }
          }

          .content-box {
            height: 6rem;
            margin: 1rem 0;
            display: flex;

            img {
              width: 26%;
            }

            .content-text {
              width: 74%;
              font-size: 0.88rem;
              padding-left: 2rem;

              .content-text-top {
                width: 15rem;
                height: 3rem;
                font-weight: 800;
                color: #202123;
                line-height: 1.6rem;
              }

              .content-text-below {
                margin-top: 1rem;
                color: #016975;
              }
            }
          }
        }

        .content-two,
        .content-three {
          height: 20rem;
          margin-top: 1rem;

          .content-text {
            margin-top: 1.4rem;
            font-weight: 800;
            color: #202123;
            font-size: 0.88rem;
          }

          .title {
            display: flex;
            align-items: center;
            font-weight: 800;
            color: #131722;
            font-size: 1.15rem;

            .title-img {
              width: 1rem;
              height: 1rem;
              display: block;
              background: url('~@/assets/imgs/yous.png') no-repeat;
              background-size: 100% 100%;
              margin-right: 1rem;
            }
          }
        }
      }
    }

    .paging {
      padding-left: 34rem;
      margin-bottom: 4rem;
    }
  }

  /* 导航栏字体 */
  .product-page /deep/.el-tabs__item {
    font-size: 1.12rem !important;
  }

  /* 被选中导航栏字体颜色 */
  .product-page /deep/ .el-tabs__item.is-active {
    color: #009bad;
  }

  /*被选中导航栏内容背景颜色 */
  .product-page /deep/ .el-tabs__active-bar {
    background-color: #009bad;
  }

  /* 取消导航栏下长边框 */
  .product-page /deep/ .el-tabs__nav-wrap::after {
    width: 0%;
  }

  // .product-page /deep/ .el-select .el-input {
  //   width: 130px;
  // }
  .product-page /deep/ .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .product-page /deep/ .el-input__inner {
    height: 3rem;
  }

  // 分页
  // 按钮大小
  .product-page /deep/ .el-pager li,
  .product-page /deep/ .btn-prev,
  .product-page /deep/ .btn-next {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    background-color: #fff !important;
    border: 1px solid #c7c7c7 !important;
  }

  // 按钮背景
  .product-page /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #016975 !important;
  }

  @media (max-width: 1530px) {
    .product-page .content {
      width: 100%;
    }
  }
</style>