import service from '@/api/util/service';

// 获得所有产品
export function loadProductSerieVO(){
    return service({
        url: '/baixin/product/getProductSeries',
        method: 'GET'
    })
}

// 根据产品类型id，获得某一种的产品
export function loadProductByType(num){
    return service({
        url: '/baixin/product/getProductByType/'+num,
        method: 'GET'
    })
}

// 根据产品类型id，获得同种产品的图片url
export function loadProductPictureByType(num){
    return service({
        url: '/baixin/product/getProductPictureByType/'+num,
        method: 'GET'
    })
}

// 根据产品id获得，产品详情
export function loadProductById(num){
    return service({
        url: '/baixin/product/getProductById/'+num,
        method: 'GET'
    })
}