<template>
  <div class="cases-particulars-page">
    <!-- 头部导航 -->
    <div class="head">
      <div class="head-left flex lan">broord</div>
      <div class="head-right flex">
        <div class="table">
          <div class="tab tabclass" @click="btns('first')">首页</div>
          <div class="tab tabclass" @click="btns('second')">关于我们</div>
          <div class="tab tabclass" @click="btns('third')">服务</div>
          <div class="tabs tabclass" @click="btns('fourth')">产品</div>
          <div class="tab tabclass" @click="btns('case')">案例</div>
          <div class="tab tabclass" @click="btns('contact')">联系我们</div>
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="logo">
      <div class="logo-name">产品</div>
    </div>
    <!-- 内容 -->
    <div class="content-box">
      <div class="content-img">
        <img :src="content.productIcon" alt="" />
      </div>
      <!-- 产品介绍 -->
      <div class="introduce">{{content.productName}}</div>
      <div class="introduce-text">{{content.productWeight}};{{content.productCapacity}}</div>
      <div class="introduce-box">
        <div class="introduce-box-left">
          <div class="details">
            <div class="box-title">产品简介</div>
            <div class="box-text">
              <p v-html="content.productProfile"></p>
            </div>
          </div>
          <div class="details">
            <div class="box-title">适用范围</div>
            <div class="box-text">
              <p v-html="content.productSuitScope"></p>
            </div>
          </div>
        </div>
        <div class="introduce-box-right">
          <div class="box-right-title">产品参数</div>
          <div class="parameter" v-if="content.productGouacheFrom">
            <div class="box-name">水粉比</div>
            <div class="box-text">{{ content.productGouacheFrom }}:{{content.productGouacheTo}}</div>
          </div>
          <!-- <div class="parameter" v-if="content.LiquidPowderRatio">
            <div class="box-name">液粉比</div>
            <div class="box-text">{{ content.LiquidPowderRatio }}</div>
          </div>
          <div class="parameter" v-if="content.ModelRatio">
            <div class="box-name">双组份比例</div>
            <div class="box-text">{{ content.ModelRatio }}</div>
          </div> -->
          <div class="parameter">
            <div class="box-name">产品状态</div>
            <div class="box-text">{{ content.productStatus }}</div>
          </div>
          <div class="parameter" v-if="content.productStorageHeat">
            <div class="box-name">储存温度</div>
            <div class="box-text">{{ content.productStorageHeat }}</div>
          </div>
          <div class="parameter" v-if="content.productHeat">
            <div class="box-name">施工温度</div>
            <div class="box-text">{{ content.productHeat }}</div>
          </div>
          <div class="parameter" v-if="content.productOperatingTime">
            <div class="box-name">可操作时间</div>
            <div class="box-text">{{ content.productOperatingTime }}</div>
          </div>
          <div class="parameter" v-if="content.productCleanTime">
            <div class="box-name">清理时间</div>
            <div class="box-text">{{ content.productCleanTime }}</div>
          </div>
          <div class="parameter" v-if="content.productBearTime">
            <div class="box-name">承受荷载所需时间</div>
            <div class="box-text">{{ content.productBearTime }}</div>
          </div>
          <div class="parameter" v-if="content.productFirstCleanTime">
            <div class="box-name">第一次清洗时间</div>
            <div class="box-text">{{ content.productFirstCleanTime }}</div>
          </div>
          <div class="parameter" v-if="content.productBetTime">
            <div class="box-name">两遍之间间隔时间</div>
            <div class="box-text">{{ content.productBetTime }}</div>
          </div>
          <div class="parameter" v-if="content.productAccessibleTime">
            <div class="box-name">可上人时间</div>
            <div class="box-text">{{ content.productAccessibleTime }}</div>
          </div>
          <div class="parameter" v-if="content.productCloseTime">
            <div class="box-name">可闭水时间</div>
            <div class="box-text">{{ content.productCloseTime }}</div>
          </div>
          <div class="parameter" v-if="content.productCuringTime">
            <div class="box-name">养护时间</div>
            <div class="box-text">{{ content.productCuringTime }}</div>
          </div>
          <div class="parameter" v-if="content.singleTrackConstructionThickness">
            <div class="box-name">单道施工厚度</div>
            <div class="box-text">{{ content.singleTrackConstructionThickness }}</div>
          </div>
          <div class="parameter" v-if="content.productTryTime">
            <div class="box-name">干固时间</div>
            <div class="box-text">{{ content.productTryTime }}</div>
          </div>
          <div class="parameter" v-if="content.productBetTime">
            <div class="box-name">两遍涂刷间隔时间</div>
            <div class="box-text">{{ content.productBetTime }}</div>
          </div>
          <div class="parameter" v-if="content.productSurfaceTime">
            <div class="box-name">表干时间</div>
            <div class="box-text">{{ content.productSurfaceTime }}</div>
          </div>
          <div class="parameter" v-if="content.productPolishTime">
            <div class="box-name">可打磨时间</div>
            <div class="box-text">{{ content.productPolishTime }}</div>
          </div>
        </div>
      </div>
      <!-- 分享 -->
      <div class="share">分享到</div>
      <div class="sharelogo"><img v-for="item3 in shares" :src="item3.url" alt="" /></div>
      <!-- 海报 -->
      <div class="poster">
        <img :src="content.pictureUrl" alt="" />
      </div>
      <!-- 猜你喜欢 -->
      <div class="like">猜你喜欢</div>
      <div class="like-box">
        <img v-for="item4 in metadata" :src="item4.productIcon" :key="item4.productId" @click="btn(item4)">
      </div>
    </div>

    <me></me>
  </div>
</template>

<script>
  import me from './me.vue';
  import { Loading } from 'element-ui';
  let loading;
  import { loadProductById,loadProductPictureByType } from '@/api/baixin/product';
  export default {
    components: {
      me
    },
    data() {
      return {
        metadata: [],
        content: '',
        activeName: 'fourth',
        likeimg: [],
        shares: [],
        titles: [],
        parameter: []
      };
    },
    mounted() {
      this.updata();
      this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
      })

    },
    created() {
      let pro = this.$route.query.item;
      this.getProductById(pro.productId); // 展示产品
      this.getProductPictureByType(pro.productTypeId); // 展示猜你喜欢
      window.scrollTo(0, 0)
    },
    destroyed() {
      sessionStorage.removeItem("product"); 
    },
    methods: {
      updata(){
        this.content = JSON.parse(sessionStorage.getItem("product"));
        this.getProductById(this.content.productId); // 展示产品
        this.getProductPictureByType(this.content.productTypeId); // 展示猜你喜欢
      },
      startLoading(){  // 使用Element loading-start 方法
        loading = Loading.service({
        lock: true,
        text: '加载中……',
        background: 'rgba(0, 0, 0, 0.7)'
       });
       loading.close();
      },
      btns(name) {
        if (name == 'first') {
          this.$router.push('/');
        }
        if (name == 'second') {
          this.$router.push('/aboutus');
        }
        if (name == 'third') {
          this.$router.push('/serve');
        }
        if (name == 'case') {
          this.$router.push('/product');
        }
        if (name == 'contact') {
          this.$router.push('/contact');
        }
      },
      btn(item) {
        console.log('我点了', item)
        // this.content = item;
        this.getProductById(item.productId);
        this.getProductPictureByType(item.productTypeId);
        this.startLoading();
      },
      async getProductById(num){
        console.info(num);
        const res = await loadProductById(num);
        this.content = res.data;
        sessionStorage.setItem("product",JSON.stringify(this.content));
        console.log(res,"单个产品的请求");
      },
      async getProductPictureByType(num){
        console.info(num);
        const res = await loadProductPictureByType(num);
        this.metadata = res.data;
        console.log(res,"猜你喜欢的请求");
      }
    }
  };
</script>
<style scoped lang="scss">
  .cases-particulars-page {
    width: 100%;
    height: 100%;
    font-family: Source Han Sans CN;

    .head {
      height: 100%;
      background-color: #fff;
      display: flex;

      .head-left {
        width: 26%;
        font-size: 2.8rem;
        font-weight: 700;
        justify-content: flex-end !important;
      }

      .head-right {
        width: 74%;
        height: 4.32rem;
        padding-top: 1rem;
      }
    }

    // logo
    .logo {
      height: 12.13rem;
      background: url('~@/assets/imgs/top2.png') no-repeat;
      background-size: 100% 100%;
      position: relative;

      .logo-name {
        position: absolute;
        left: 21rem;
        top: 3rem;
        font-size: 3.75rem;
        font-weight: 800;
        color: #fff;
      }
    }

    .content-box {
      width: 64.5%;
      height: 100%;
      margin: 0 auto;
      padding: 2rem 0;
      background-color: #fff;

      .content-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60%;
        height: 22rem;
        background-size: 100% 100%;
        background-color: #016975;

        img {
          width: 24%;
        }
      }

      .introduce {
        font-size: 1.88rem;
        font-weight: 800;
        padding-left: 1rem;
        color: #222222;
        margin: 2rem 0 1rem 0;
      }

      .introduce-text {
        font-size: 1.15rem;
        padding-left: 1rem;
        margin-bottom: 2rem;
      }

      .introduce-box {
        width: 100%;
        height: 20rem;
        display: flex;
        padding-left: 1rem;

        .introduce-box-left {
          width: 65%;
          height: 100%;

          .details {
            font-size: 1.15rem;
            color: #6b6b6b;

            .box-title {
              font-weight: 500;
              color: #222222;
              margin-bottom: 2rem;
            }

            .box-text {
              width: 90%;
              margin-bottom: 4rem;
            }
          }
        }

        .introduce-box-right {
          width: 35%;
          height: 100%;
          color: #222222;

          .box-right-title {
            font-size: 1.15rem;
            font-weight: 800;
            margin-bottom: 2rem;
          }

          .parameter {
            display: flex;
            width: 64%;
            height: 15%;
            justify-content: space-between;
            align-items: center;
            font-size: 0.88rem;

            .box-name {
              font-size: 1rem;
              font-weight: 700;
            }
          }
        }
      }

      .share {
        margin: 4rem 0 2rem 0;
        font-weight: 800;
        color: #222222;
        font-size: 1.15rem;
        padding-left: 1rem;
      }

      .sharelogo {
        display: flex;
        margin-bottom: 5rem;
        padding-left: 1rem;

        img {
          width: 3.07rem;
          display: block;
          margin-right: 2rem;
        }
      }

      // 海报
      .poster {
        width: 100%;
        // height: 140rem;
        // background: url('~@/assets/imgs/haibao.png') no-repeat;
        background-size: 100% 100%;

        img {
          width: 100%;
        }
      }

      // 猜你喜欢
      .like {
        font-size: 1.5rem;
        margin: 2rem 0;
        font-weight: 800;
        color: #1c1b1b;
      }

      .like-box {
        width: 100%;
        height: 16.75rem;
        display: flex;
        justify-content: space-between;
        overflow: scroll;

        img {
          display: block;
          // width: 16.4rem;
          height: 100%;
        }
      }
    }
  }

  /* 导航栏字体 */
  .cases-particulars-page /deep/.el-tabs__item {
    font-size: 1.12rem !important;
  }

  /* 被选中导航栏字体颜色 */
  .cases-particulars-page /deep/ .el-tabs__item.is-active {
    color: #009bad;
  }

  /*被选中导航栏内容背景颜色 */
  .cases-particulars-page /deep/ .el-tabs__active-bar {
    background-color: #009bad;
  }

  /* 取消导航栏下长边框 */
  .cases-particulars-page /deep/ .el-tabs__nav-wrap::after {
    width: 0%;
  }

  @media (max-width: 1530px) {
    .cases-particulars-page .content-box {
      width: 100%;
      // padding: 5rem 0;
    }
  }
</style>