import service from '@/api/util/service';

/**
 * 
 * @returns 获得树级菜单
 */
export function loadTreeMenu(num){
    return service({
        url: '/baixin/menu/getTreeMenu/'+num,
        method: 'GET'
    })
}

/**
 * 
 * @returns 获得首页常量配置
 */
export function loadHomeSetting(){
    return service({
        url: '/baixin/menu/getHomeSetting',
        method: 'GET'
    })
}

/**
 * 
 * @returns 返回某个菜单的所有文章
 */
export function loadMenuArticles(num){
    return service({
        url: '/baixin/menu/getMenuArticles/'+num,
        method: 'GET'
    })
}