<template>
  <div class="serve-page">
    <!-- 头部导航 -->
    <div class="head">
      <div class="head-left flex lan">broord</div>
      <div class="head-right flex">
        <div class="table">
          <div class="tab tabclass" @click="btn('first')">首页</div>
          <div class="tab tabclass" @click="btn('second')">关于我们</div>
          <div class="tabs tabclass" @click="btn('third')">服务</div>
          <div class="tab tabclass" @click="btn('fourth')">产品</div>
          <div class="tab tabclass" @click="btn('case')">案例</div>
          <div class="tab tabclass" @click="btn('contact')">联系我们</div>
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="logo">
      <div class="logo-name">服务</div>
    </div>
    <!-- 公司简介 -->
    <div class="company">
      <div class="company-left">
        <div class="company-name">{{topArticle.englishName}}</div>
        <div class="company-facilitator">{{topArticle.title}}</div>
        <div class="company-facilitator">{{topArticle.subtitle}}</div>
        <div class="company-charge">
          <!-- 介绍服务内容相关文案,介绍服务内容相关文案介绍服务内容相关文案
          介绍服务内容相关文案,介绍服务内容相关文案. -->
          <p v-html="topArticle.description"></p>
        </div>
        <div class="company-more flex" @click="btn('contact')">联系我们</div>
      </div>
      <div class=" company-right">
        <img :src="topArticle.pictureUrl" alt="" />
      </div>
    </div>
    <!-- 样板制作 -->
    <div class="make">
      <div class="title">{{making.subtitle}}</div>
      <div class="titles">
        <span>{{making.title}} &nbsp;</span>
        <span style="font-size: 14px">{{making.englishName}}</span>
      </div>
      <div class="make-box">
        <div class="make-box-a"></div>
        <div class="make-box-b">
          <div class="company-leftes">
            <div class="company-name">{{sampleMaking.englishName}}</div>
            <div class="company-facilitator">{{sampleMaking.title}}</div>
            <div class="company-charge">
              <!-- 样板可达到效果展示 -->
              <p v-html="sampleMaking.description"></p>
            </div>
            <!-- <div class="company-charge">样板可做为施工示范的标准</div>
            <div class="company-charge">样板可用于各种测试</div>
            <div class="company-charge">如（拉拔、涂布率、冲击钻冲撞测试)</div> -->
            <div class="company-more flex" @click="btn('contact')">了解更多</div>
            <div class="logo"></div>
          </div>
        </div>
      </div>
      <div class="make-boxs">
        <div class="make-box-a">
          <div class="company-left">
            <div class="company-name">{{fieldService.englishName}}</div>
            <div class="company-facilitator chao1">{{fieldService.title}}</div>
            <div class="company-charge chao1">
              <!-- 观察指导现场工人对材料使用情况 -->
              <p v-html="fieldService.description"></p>
            </div>
            <!-- <div class="company-charge chao1">协助工人提高施工质量</div>
            <div class="company-charge chao1">协助甲方解决现场问题</div> -->
            <div class="company-more flex" @click="btn('contact')">了解更多</div>
            <div class="logo"></div>
          </div>
        </div>
        <div class="make-box-b"></div>
      </div>
    </div>
    <!-- 涂布率测试 -->
    <div class="test">
      <div class="title">{{coating.subtitle}}</div>
      <div class="titles">
        <span>{{coating.title}}&nbsp;</span>
        <span style="font-size: 14px">{{coating.englishName}}</span>
      </div>
      <div class="content">
        <!-- <img :src="item.url" alt="" v-for="item in spreading" /> -->
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img :src="item" alt="" v-for="(item,index) in spreading" :key="index" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 技术交底 -->
    <div class="technology">
      <div class="title">{{skill.subtitle}}</div>
      <div class="titles">
        <span>{{skill.title}}&nbsp;</span>
        <span style="font-size: 14px">{{skill.englishName}}</span>
      </div>
      <div class="content flex">
        <div class="content-box">
          <div class="content-one" v-for="(item1,index1) in hand" :key="index1">
            <div class="text">{{ item1.text }}</div>
            <div class="kuan"></div>
          </div>
        </div>
        <div class="content-img"></div>
      </div>
    </div>
    <!-- 巡检 -->
    <div class="polling">
      <div class="title">{{Patrol.subtitle}}</div>
      <div class="titles">
        <span>{{Patrol.title}}&nbsp;</span>
        <span style="font-size: 14px">{{Patrol.englishName}}</span>
      </div>
      <div class="content">
        <div class="content-img"></div>
        <div class="content-box">
          <div class="company-facilitator">{{guidance.title}}</div>
          <div class="company-charge">
            <!-- 观察指导现场工人对材料使用情况 -->
            <p v-html="guidance.description"></p>
          </div>
          <!-- <div class="company-charge">协助工人提高施工质量</div>
          <div class="company-charge">协助甲方解决现场问题</div> -->
          <div class="logo"></div>
        </div>
      </div>
    </div>
    <!-- 售后 -->
    <div class="after-sale">
      <div class="after-sale-box">
        <div class="after-sale-box-left">
          <img :src="Submission.pictureUrl" alt="" />
          <div class="company-left">
            <div class="company-name">{{Submission.englishName}}</div>
            <div class="company-facilitator">{{Submission.title}}</div>
            <div class="company-charge">
              <!-- 合格证明，确保建筑材料符合设计要求。提供送检检测证明，协助验收。
              保证建筑工程的质量和建筑使用者的人身财产安全，维护社会的稳定。 -->
              <p v-html="Submission.description"></p>
            </div>
            <div class="mouse" @click="btn('contact')">了解更多</div>
            <div class="logo"></div>
          </div>
        </div>
        <div class="after-sale-box-right">
          <div class="box-right-top">
            <div class="box-img"><img :src="complaint.pictureUrl" /></div>
            <div class="box-text">
              <div class="company-facilitator">{{complaint.title}}</div>
              <div class="company-charge">
                <!-- 规范的投诉处理流程,减少推诿的发生
                认真分析投诉原因，服务理念深入人心
                积极总结投诉问题，改进/完善自身工作。
                认真、诚信、负责，提高使用者满意度， 构建良好合作关系 -->
                <p v-html="complaint.description"></p>
              </div>
              <div class="mouse" @click="btn('contact')">了解更多</div>
              <div class="logo"></div>
            </div>
          </div>
          <div class="box-right-below">
            <div class="box-img"><img :src="followUp.pictureUrl" /></div>
            <div class="box-text">
              <div class="company-facilitator">{{followUp.title}}</div>
              <div class="company-charge">
                <!-- 及时发现问题，找出问题及时改进。 了解客户对产品的需求及满意度，
                改进自身产品。提高客户对企业/产 品的美誉度和忠诚度。 -->
                <p v-html="followUp.description"></p>
              </div>
              <!-- <a href="" style="text-decoration: underline" @click="btn('contact')">了解更多</a> -->
              <div class="mouse" @click="btn('contact')">了解更多</div>
              <div class="logo"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <me></me>
  </div>
</template>

<script>
  import me from "./me.vue";
  import "swiper/dist/js/swiper";
  import "swiper/dist/css/swiper.css";
  import Swiper from "swiper";
  import {loadMenuArticles} from '@/api/baixin/menu';
  export default {
    components: {
      me,
    },
    data() {
      return {
        activeName: "third",
        topArticle: {},
        making: {},
        sampleMaking: {},
        fieldService: {},
        coating: {},
        skill: {},
        material: {},
        Patrol: {},
        guidance: {},
        Submission: {},
        complaint: {},
        followUp: {},
        hand: [],
        spreading: [],
      };
    },
    mounted() {
      this.getMenuArticle(3);
      this.slide();
      this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
      })
    },
    created() {},
    methods: {
      btn(name) {
        if (name == "first") {
          this.$router.push("/");
        }
        if (name == "second") {
          this.$router.push("/aboutus");
        }
        if (name == "case") {
          this.$router.push("/product");
        }
        if (name == "fourth") {
          this.$router.push("/cases");
        }
        if (name == "contact") {
          this.$router.push("/contact");
        }
      },
      slide() {
        new Swiper(".swiper-container", {
          // direction: 'vertical', // 垂直切换选项
          //mousewheel: true, //滚轮
          // autoplay: {
          //   //自动开始
          //   delay: 2500, //时间间隔
          //   disableOnInteraction: false //*手动操作轮播图后不会暂停*
          // },
          // loop: true, // 循环模式选项
          // 如果需要分页器
          // pagination: {
          //   el: '.swiper-pagination',
          //   clickable: true // 分页器可以点击
          // },
          // 如果需要前进后退按钮
          // navigation: {
          //   nextEl: '.swiper-button-next',
          //   prevEl: '.swiper-button-prev'
          // },
          // 如果需要滚动条
          // scrollbar: {
          //   el: '.swiper-scrollbar'
          // }
        });
      },
      async getMenuArticle(num){
        const res = await loadMenuArticles(num);
        this.topArticle = res.data[0].articleVOS[11];
        this.making = res.data[0].articleVOS[0];
        this.sampleMaking = res.data[0].articleVOS[1];
        this.fieldService = res.data[0].articleVOS[2];
        this.coating = res.data[0].articleVOS[3];
        let spreading = this.coating.pictureUrl.split(',');
        for(var i = 1; i < spreading.length; i++) {
            spreading[i] = '/api' + spreading[i];
        }
        this.spreading = spreading;
        // console.info(this.spreading);
        this.skill = res.data[0].articleVOS[4];
        this.material = res.data[0].articleVOS[5];
        this.hand.push({"text":this.material.englishName});
        this.hand.push({"text":this.material.title});
        this.hand.push({"text":this.material.subtitle});
        this.Patrol = res.data[0].articleVOS[6];
        this.guidance = res.data[0].articleVOS[7];
        this.Submission = res.data[0].articleVOS[8];
        this.complaint = res.data[0].articleVOS[9];
        this.followUp = res.data[0].articleVOS[10];
        // console.log(res,"服务文章"); 
      }
    },
  };
</script>
<style scoped lang="scss">
  .serve-page {
    width: 100%;
    height: 100%;

    font-family: Source Han Sans CN;

    .head {
      height: 100%;
      background-color: #fff;
      display: flex;

      .head-left {
        width: 26%;
        font-size: 2.8rem;
        font-weight: 700;
        justify-content: flex-end !important;
      }

      .head-right {
        width: 74%;
        height: 4.32rem;
        padding-top: 1rem;
      }
    }

    // logo
    .logo {
      height: 12.13rem;
      background: url("~@/assets/imgs/top2.png") no-repeat;
      background-size: 100% 100%;
      position: relative;

      .logo-name {
        position: absolute;
        left: 21rem;
        top: 3rem;
        font-size: 3.75rem;
        font-weight: 800;
        color: #fff;
      }
    }

    // 公司介绍
    .company {
      min-height: 39.44rem;
      display: flex;
      position: relative;
      background-color: #eef6fd;

      .company-left {
        width: 63.9%;
        height: 100%;
        border-top-right-radius: 6rem;
        padding-right: -50px;
        padding-left: 21rem;
        padding-top: 8rem;

        .company-name {
          margin-bottom: 2rem;
          font-size: 1.12rem;
          font-size: 1.5rem;
          color: #044386;
        }

        .company-symbol {
          width: 1.89rem;
          height: 0.38rem;
          background-color: #034387;
          margin-top: 1rem;
        }

        .company-facilitator {
          font-size: 3.37rem;
          font-weight: 800;
        }

        .company-charge {
          width: 60%;
          font-size: 1.13rem;
          color: #888f9a;
          margin-top: 3rem;
        }

        .company-introduction {
          margin: 3em 0;
        }

        .company-more {
          width: 10.2rem;
          height: 2.94rem;
          background-color: #016975;
          color: #fff;
          margin-top: 4rem;
          cursor: pointer;
          font-size: 1.15rem;
        }
      }

      .company-right {
        width: 36.1%;
        height: 34rem;
        position: absolute;
        right: 0rem;
        bottom: 0;
        padding-right: 6.8rem;

        img {
          height: 100%;
        }
      }
    }

    // 样板制作
    .make {
      height: 68.75rem;
      background-color: #fff;
      padding: 6rem 21rem;

      .title {
        font-size: 1.5rem;
        color: #009bad;
        margin-bottom: 1rem;
      }

      .titles {
        font-size: 1.88rem;
      }
    }

    .make-box,
    .make-boxs {
      width: 100%;
      height: 40%;
    }

    .make-box {
      margin: 4.5rem 0;
      display: flex;
      justify-content: space-between;

      .make-box-a,
      .make-box-b {
        width: 46%;
      }

      .make-box-a {
        background: url("~@/assets/imgs/yang1.png") no-repeat;
        background-size: 100% 100%;
      }

      .make-box-b {
        .company-leftes {
          height: 100%;
          position: relative;

          .company-name {
            margin-bottom: 3rem;
            font-size: 1.5rem;
            color: #044386;
          }

          .logo {
            width: 3.38rem;
            height: 2.88rem;
            position: absolute;
            top: 0;
            right: 0;
            background: url("~@/assets/imgs/hao.png") no-repeat;
            background-size: 100% 100%;
          }

          .company-symbol {
            width: 1.89rem;
            height: 0.38rem;
            background-color: #034387;
            margin-top: 1rem;
          }

          .company-facilitator {
            font-size: 3.37rem;
            font-weight: 800;
            margin-bottom: 3rem;
          }

          .company-charge {
            width: 68%;
            font-size: 1.13rem;
            color: #888f9a;
            margin-top: 0.5rem;
          }

          .company-introduction {
            margin: 3em 0;
          }

          .company-more {
            width: 9.44rem;
            height: 3.7rem;
            background: #016975;
            border-radius: 10px;
            color: #fff;
            margin-top: 4rem;
            cursor: pointer;
            font-size: 1.5rem;
          }
        }
      }
    }

    .make-boxs {
      display: flex;
      justify-content: space-between;

      .make-box-a,
      .make-box-b {
        width: 46%;
        height: 100%;
      }

      .make-box-a {
        .company-left {
          height: 100%;
          position: relative;

          .company-name {
            margin-bottom: 3rem;
            font-size: 1.12rem;
            font-size: 1.5rem;
            color: #044386;
            text-align: right;
          }

          .logo {
            width: 3.38rem;
            height: 2.88rem;
            position: absolute;
            top: 0;
            left: 0;
            background: url("~@/assets/imgs/hao.png") no-repeat;
            background-size: 100% 100%;
          }

          .company-symbol {
            width: 1.89rem;
            height: 0.38rem;
            background-color: #034387;
            margin-top: 1rem;
          }

          .company-facilitator {
            font-size: 3.37rem;
            font-weight: 800;
            margin-bottom: 5rem;
            width: 100%;
            text-align: right;
          }

          .company-charge {
            width: 100%;
            font-size: 1.13rem;
            color: #888f9a;
            margin-top: 0.5rem;
            text-align: right;
          }

          .company-introduction {
            margin: 3em 0;
          }

          .company-more {
            width: 9.44rem;
            height: 3.7rem;
            background: #016975;
            border-radius: 10px;
            color: #fff;
            margin-top: 4rem;
            cursor: pointer;
            font-size: 1.5rem;
            position: absolute;
            right: 0;
            button: 0;
          }
        }
      }

      .make-box-b {
        background: url("~@/assets/imgs/laba.png") no-repeat;
        background-size: 100% 100%;
      }
    }

    // 涂布率测试
    .test {
      height: 42.13rem;
      background: #f7f7f7;

      .title {
        font-size: 1.5rem;
        color: #009bad;
        padding: 2rem 1rem 1rem 21rem;
      }

      .titles {
        font-size: 1.88rem;
        padding-left: 21rem;
        margin-bottom: 3rem;
      }

      .content {
        width: 119rem;
        display: flex;

        // overflow: scroll;
        img {
          display: block;
          width: 24rem;
          height: 24.5rem;
          margin: 0 1rem;
          border-radius: 5px;
        }
      }
    }

    // 技术交底
    .technology {
      height: 42.13rem;
      background: #fff;

      .title {
        font-size: 24px;
        color: #009bad;
        padding: 2rem 1rem 1rem 21rem;
      }

      .titles {
        font-size: 1.88rem;
        padding-left: 21rem;
        margin-bottom: 3rem;
      }

      .content {
        width: 76rem;
        height: 28rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .content-box {
          width: 43%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .content-one {
            width: 27rem;
            height: 8.3rem;
            background-color: #ececec;
            border-radius: 5px;
            padding-left: 4rem;
            display: flex;
            align-items: center;
            position: relative;

            .text {
              width: 68%;
              font-size: 1.5rem;
            }

            .kuan {
              width: 1.13rem;
              height: 1.57rem;
              background: #f7f7f7;
              border: 4px solid #016975;
              border-radius: 5px;
              position: absolute;
              right: 3rem;
              top: 3rem;
            }
          }
        }

        .content-img {
          width: 52%;
          height: 100%;
          background: url("~@/assets/imgs/jd.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    // 巡检
    .polling {
      width: 100%;
      height: 43.25rem;
      background: #fafcfe;

      .title {
        font-size: 1.5rem;
        color: #009bad;
        padding: 2rem 1rem 1rem 21rem;
      }

      .titles {
        font-size: 1.88rem;
        padding-left: 21rem;
        margin-bottom: 3rem;
      }

      .content {
        width: 76rem;
        height: 28rem;
        padding-left: 21rem;
        display: flex;
        justify-content: space-between;

        .content-img,
        .content-box {
          width: 45%;
          height: 100%;

          .company-facilitator {
            font-size: 2.25rem;
            font-weight: 800;
            margin: 4rem 0;
          }

          .company-charge {
            width: 68%;
            font-size: 1.5rem;
            color: #888f9a;
            margin: 2rem 0;
          }

          .logo {
            width: 1.13rem;
            height: 1.57rem;
            background: #f7f7f7;
            border: 4px solid #044386;
            border-radius: 5px;
            position: absolute;
            top: 4.5rem;
            right: 4rem;
          }
        }

        .content-img {
          background: url("~@/assets/imgs/cx.png") no-repeat;
          background-size: 100% 100%;
        }

        .content-box {
          border-radius: 5px;
          padding-left: 3rem;
          position: relative;
          background: url("~@/assets/imgs/bian.png") no-repeat;
          background-size: 100% 100%;
          box-shadow: 0px 1px 29px 0px rgba(0, 0, 0, 0.15);
        }
      }
    }

    // 售后
    .after-sale {
      height: 47.5rem;
      background: #fff;
      padding-left: 21rem;
      display: flex;
      align-items: center;

      .after-sale-box {
        width: 76rem;
        height: 32rem;
        display: flex;
        justify-content: space-between;

        .after-sale-box-left,
        .after-sale-box-right {
          width: 48%;
          height: 100%;
        }

        .after-sale-box-left {
          position: relative;

          img {
            width: 100%;
            height: 380px;
            display: block;
          }

          .company-left {
            width: 83%;
            background: #ffffff;
            box-shadow: 0px 1px 29px 0px rgba(0, 0, 0, 0.15);
            padding-left: 2rem;
            position: absolute;
            height: 221px;
            bottom: 0;
            left: 2.1rem;

            .company-name {
              padding: 1rem 0;
              font-size: 1.15rem;
              color: #044386;
            }

            .company-symbol {
              width: 1.89rem;
              height: 0.38rem;
              background-color: #034387;
              margin-top: 1rem;
            }

            .company-facilitator {
              font-size: 1.5rem;
              font-weight: 800;
            }

            .company-charge {
              width: 84%;
              font-size: 0.88rem;
              color: #434343;
              margin: 1rem 0;
              line-height: 1.5rem;
              font-family: Source Han Sans CN;
              font-weight: 500;
            }

            .company-introduction {
              margin: 3em 0;
            }

            .company-more {
              color: #fff;
              margin-top: 4rem;
              cursor: pointer;
              font-size: 18px;
            }

            .logo {
              width: 20px;
              height: 1.15rem;
              position: absolute;
              right: 3rem;
              top: 3rem;
              background: url("~@/assets/imgs/hao.png") no-repeat;
              background-size: 100% 100%;
            }
          }
        }

        .after-sale-box-right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .box-right-top,
          .box-right-below {
            width: 100%;
            height: 15.13rem;
            display: flex;
            box-shadow: 0px 1px 29px 0px rgba(0, 0, 0, 0.15);
            position: relative;

            .box-text {
              padding-left: 1rem;
            }

            .box-img,
            .box-text {
              width: 50%;
              height: 100%;

              img {
                width: 100%;
                height: 100%;
              }

              .company-facilitator {
                font-size: 1.5rem;
                font-weight: 800;
                margin: 1rem 0;
              }

              .company-charge {
                width: 84%;
                font-size: 0.88rem;
                color: #434343;
                margin: 1rem 0;
                width: 249px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                line-height: 1.5rem;
              }

              .company-introduction {
                margin: 3em 0;
              }

              .company-more {
                color: #fff;
                margin-top: 4rem;
                cursor: pointer;
                font-size: 1.15rem;
              }

              .logo {
                width: 4.63rem;
                height: 4.6rem;
                position: absolute;
                right: 0;
                bottom: 0;
                background: url("~@/assets/imgs/bian1.png") no-repeat;
                background-size: 100% 100%;
              }
            }

            .box-img {
              width: 44%;
            }
          }
        }
      }
    }
  }

  /* 导航栏字体 */
  .serve-page /deep/.el-tabs__item {
    font-size: 1.12rem !important;
  }

  /* 被选中导航栏字体颜色 */
  .serve-page /deep/ .el-tabs__item.is-active {
    color: #009bad;
  }

  /*被选中导航栏内容背景颜色 */
  .serve-page /deep/ .el-tabs__active-bar {
    background-color: #009bad;
  }

  /* 取消导航栏下长边框 */
  .serve-page /deep/ .el-tabs__nav-wrap::after {
    width: 0%;
  }

  // 滑动器
  .swiper-container-horizontal {
    width: 100%;
    height: 24.5rem;
  }

  .swiper-slide-active {
    display: flex;
  }

  @media (max-width: 1530px) {
    .serve-page .company-left {
      padding: 2rem 6rem !important;
    }

    .serve-page .test .title,
    .serve-page .test .titles,
    .serve-page .technology .title,
    .serve-page .technology .titles,
    .serve-page .polling .title,
    .serve-page .polling .titles {
      padding-left: 6rem;
    }

    .serve-page .test {
      height: 33rem;
    }

    .serve-page .test .content img {
      width: 16rem;
      height: 16.5rem;
    }

    .serve-page .test .content {
      width: 100%;
    }

    .serve-page .polling .content,
    .serve-page .after-sale {
      padding: 0 !important;
    }

    .serve-page .after-sale .after-sale-box .after-sale-box-left .company-left {
      padding: 1rem !important;
    }

    .me-page .me-box {
      padding: 0 3rem;
    }
  }
</style>