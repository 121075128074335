<template>
  <div class="aboutus-page">
    <!-- 头部导航 -->
    <div class="head">
      <div class="head-left flex lan">broord</div>
      <div class="head-right flex">
        <div class="table">
          <div class="tab tabclass" @click="btn('first')">首页</div>
          <div class="tabs tabclass" @click="btn('second')">关于我们</div>
          <div class="tab tabclass" @click="btn('third')">服务</div>
          <div class="tab tabclass" @click="btn('fourth')">产品</div>
          <div class="tab tabclass" @click="btn('case')">案例</div>
          <div class="tab tabclass" @click="btn('contact')">联系我们</div>
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="logo">
      <div class="logo-name">关于我们</div>
    </div>
    <!-- 公司简介 -->

    <div class="company flex">
      <div class="company-left">
        <div class="company-name">
          <!-- 公司简介 -->
          {{profileArticle.subtitle}}
        </div>
        <div class="company-symbol"></div>
        <div class="company-facilitator">{{profileArticle.title}}</div>
        <div class="company-charge "><p v-html="profileArticle.description"></p></div>
        <div class="company-more flex" @click="btn('contact')">了解更多</div>
      </div>
      <div class="company-right"><img :src="profileArticle.pictureUrl" alt="" /></div>
    </div>
    <!-- 使命 -->
    <div class="mission">
      <div class="mission-name flex">
        <span>企业</span>
        <span class="lan">使命</span>
      </div>
      <div class="mission-box">
        <div class="mission-box-left"></div>
        <div class="mission-box-right">
          <div class="mission-title">{{missionArticle.englishName}}</div>
          <div class="mission-content chao2">
            <p v-html="missionArticle.description"></p>
          </div>
          <div class="mission-merchant">{{missionArticle.subtitle}}</div>
          <div class="mission-company">{{missionArticle.title}}</div>
          <img :src="missionArticle.pictureUrl" alt="" />
        </div>
      </div>
    </div>
    <!-- 资质 -->
    <div class="certification">
      <div class="certification-name flex">证书资质</div>
      <div class="certification-box">
        <div class="certification-box-top">
          <div class="certification-img" v-for="item in certificates" :key="item.picId"><img :src="item.picUrl" /></div>
        </div>
        <div class="certification-box-below">
          <div class="certification-text flex">
            << PREV ITEM</div>
              <div class="certification-text flex">NEXT ITEM >></div>
          </div>
        </div>
      </div>
      <!-- 荣誉 -->
      <div class="honor">
        <div class="honor-name flex">奖项荣誉</div>
        <div class="honor-box">
          <div class="honor-box-img flex" v-for="item2 in honor" :key="item2.picId"><img :src="item2.picUrl" alt="" /></div>
        </div>
      </div>
      <!-- 团队 -->
      <div class="group ">
        <div class="group-name flex ">
          <span>我们的&nbsp&nbsp</span>
          <span class="lan">服务团队</span>
        </div>
        <div class="content">
          <div class="content-box " v-for="item6 in groups" :key="item6.articleId">
            <img :src="item6.pictureUrl" alt="" />
            <div class="advanced">高级工程师</div>
            <div class="name">{{ item6.title }}</div>
          </div>
        </div>
      </div>
      <!-- broord图 -->
      <div class="borrid">
        <div class="borrid-1">追求卓越服务</div>
        <div class="borrid-2">尽善尽美</div>
      </div>
      <!-- 新闻 -->
      <div class="journalism">
        <div class="journalism-name flex">
          <span>最新&nbsp&nbsp</span>
          <span class="lan">新闻</span>
        </div>
        <div class="content">
          <div class="content-box" v-for="item5 in hotnews" :key="item5.articleId">
            <img :src="item5.pictureUrl" />
            <!-- <div class="times">{{ item5.time }}</div> -->
            <div class="title">
              <p v-html="item5.description"></p>
            </div>
          </div>
        </div>
        <div class="more flex" @click="btn('case')">VIEW ALL</div>
      </div>
      <me></me>
    </div>
</template>

<script>
  import me from './me.vue';
  import {loadPictures} from '@/api/baixin/picture';
  import {loadMenuArticles} from '@/api/baixin/menu';
  export default {
    components: {
      me
    },
    data() {
      return {
        activeName: 'second',
        groups: [],
        certificates: [],
        honor: [],
        hotnews: [],
        profileArticle: [],
        missionArticle: []
      };
    },
    mounted() {
      this.getPicAll();
      this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
      })
    },
    created() {},
    methods: {
      btn(name) {
        if (name == 'first') {
          this.$router.push('/');
        }
        if (name == 'third') {
          this.$router.push('/serve');
        }
        if (name == 'case') {
          this.$router.push('/product');
        }
        if (name == 'fourth') {
          this.$router.push('/cases');
        }
        if (name == 'contact') {
          this.$router.push('/contact');
        }
      },
      async getCertPictures(num){
        const res = await loadPictures(num);
        this.certificates = res.data;
        // console.log(res,"证书图片请求");
      },
      async getHonorPictures(num){
        const res = await loadPictures(num);
        this.honor = res.data;
        // console.log(res,"荣誉图片请求");
      },
      async getMenuArticle(num){
        const res = await loadMenuArticles(num);
        this.profileArticle = res.data[0].articleVOS[0];
        this.missionArticle = res.data[1].articleVOS[0];
        this.groups = res.data[2].articleVOS;
        this.hotnews = res.data[4].articleVOS;
        // console.log(res,"该菜单下的文章");
      },
      getPicAll(){
        this.getMenuArticle(2);
        this.getCertPictures("005");
        this.getHonorPictures("006");
      }
    }
  };
</script>
<style scoped lang="scss">
  .aboutus-page {
    width: 100%;
    height: 100%;
    font-family: Source Han Sans CN;

    .head {
      height: 100%;
      background-color: #fff;
      display: flex;

      .head-left {
        width: 26%;
        font-size: 2.8rem;
        font-weight: 700;
        justify-content: flex-end !important;
      }

      .head-right {
        width: 74%;
        height: 4.32rem;
        padding-top: 1rem;
      }
    }

    // logo
    .logo {
      height: 12.13rem;
      background: url('~@/assets/imgs/top2.png') no-repeat;
      background-size: 100% 100%;
      position: relative;

      .logo-name {
        position: absolute;
        left: 21rem;
        top: 3rem;
        font-size: 3.75rem;
        font-weight: 800;
        color: #fff;
      }
    }

    // 公司介绍
    .company {
      height: 39.44rem;
      background-color: #f7f7f7;

      .company-left {
        width: 36rem;
        height: 28rem;
        padding-right: 4rem;

        .company-name {
          // line-height: 2rem;
          font-size: 1.12rem;
        }

        .company-symbol {
          width: 1.89rem;
          height: 0.38rem;
          background-color: #034387;
          margin-top: 1rem;
        }

        .company-facilitator {
          font-size: 3.37rem;
          margin: 4rem 0;
        }

        .company-charge {
          font-size: 1.13rem;
          color: #888f9a;
        }

        .company-introduction {
          margin: 3em 0;
        }

        .company-more {
          width: 10.2rem;
          height: 2.94rem;
          background-color: #009bad;
          color: #fff;
          margin-top: 4rem;
          cursor: pointer;
          font-size: 1.15rem;
        }
      }

      .company-right {
        width: 36rem;
        height: 28rem;

        img {
          width: 100%;
        }
      }
    }

    // 使命
    .mission {
      height: 36.57rem;
      background-color: #fafafa;

      .mission-name {
        font-weight: 800;
        font-size: 1.9rem;
        height: 24%;
      }

      .mission-box {
        width: 60rem;
        height: 24rem;
        margin: 0 auto;
        display: flex;

        .mission-box-left {
          width: 11.063rem;
          height: 11.94rem;
          border: 5px solid #016975;
        }

        .mission-box-right {
          margin-left: 3rem;
          width: 43.5rem;
          height: 24rem;
          position: relative;

          .mission-title {
            font-weight: bold;
            color: #222222;
            font-size: 2.25rem;
          }

          .mission-content {
            width: 38rem;
            height: 3rem;
            font-weight: 400;
            font-style: italic;
            color: #888f9a;
            font-size: 1.12rem;
            margin: 4rem 0 3rem 0;
          }

          .mission-merchant {
            font-size: 1.12rem;
            color: #2e2d2d;
            margin-bottom: 1rem;
          }

          .mission-company {
            font-size: 0.88rem;
            color: #009bad;
          }

          img {
            width: 12.07rem;
            height: 12.25rem;
            display: block;
            position: absolute;
            right: 0.1rem;
            bottom: 0.1rem;
          }
        }
      }
    }

    // 资质
    .certification {
      height: 40rem;
      background-color: #fff;

      .certification-name {
        font-weight: 800;
        font-size: 1.9rem;
        height: 24%;
        color: #009bad;
      }

      .certification-box {
        width: 76rem;
        height: 28rem;
        margin: 0 auto;

        .certification-box-top {
          height: 80%;
          display: flex;
          justify-content: space-between;

          .certification-img {
            width: 16.38rem;
            height: 22.32rem;
            background-color: #c4cdd4;

            img {
              width: 80%;
              display: block;
              margin: 2rem auto;
            }
          }
        }

        .certification-box-below {
          height: 20%;
          display: flex;
          justify-content: space-between;

          .certification-text {
            width: 20%;
            height: 100%;
            cursor: pointer;
          }
        }
      }
    }

    // 荣誉
    .honor {
      // height: 61rem;
      background: #f7f7f7;
      padding-top: 3rem;

      .honor-name {
        font-weight: 800;
        font-size: 1.88rem;
        height: 16%;
        color: #009bad;
        margin-bottom: 3rem;
      }

      .honor-box {
        width: 80rem;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;

        .honor-box-img {
          width: 18%;
          height: 22%;
          background: #ffffff;
          margin: 0 3.5rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    // 团队
    .group {
      height: 43.75rem;
      background-color: #fff;

      .group-name {
        height: 23%;
        font-size: 1.9rem;
        font-family: Source Han Sans CN;
        font-weight: 800;
        color: #363636;
      }

      .content {
        width: 64%;
        height: 62%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        display: flex;

        .content-box {
          width: 28%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .advanced {
          position: absolute;
          left: 1rem;
          bottom: 3rem;
          font-size: 0.88rem;
          color: #044386;
        }

        .name {
          position: absolute;
          left: 1rem;
          bottom: 1rem;
          font-size: 1rem;
        }
      }
    }

    // borrid图
    .borrid {
      height: 26rem;
      background: url('~@/assets/imgs/borrid.png') no-repeat;
      background-size: 100% 100%;
      position: relative;

      2 {}

      .borrid-1,
      .borrid-2 {
        width: 23rem;
        height: 5rem;
        font-size: 3.75rem;
        color: #192a3d;
        font-weight: 800;
        position: absolute;
        left: 22rem;
        top: 8rem;
      }

      .borrid-2 {
        top: 12rem;
      }
    }

    // 新闻
    .journalism {
      height: 44rem;
      background-color: #f7f7f7;

      .journalism-name {
        height: 22%;
        font-size: 1.9rem;
        font-family: Source Han Sans CN;
        font-weight: 800;
      }

      .content {
        width: 58%;
        height: 57%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .content-box {
          width: 22.5rem;
          height: 22.88rem;
          background-color: #fff;

          img {
            width: 100%;
            height: 15.19rem;
            display: block;
            border-radius: 3px;
          }

          .times {
            color: #044386;
            font-size: 1rem;
            margin: 1.5rem 1.5rem;
          }

          .title {
            font-size: 1.12rem;
            color: #333333;
            margin: 0rem 1.5rem;
          }
        }
      }

      .more {
        width: 10.19rem;
        height: 2.94rem;
        background: #009bad;
        color: #fff;
        margin: 0 auto;
        margin-top: 1.5rem;
        cursor: pointer;
        font-size: 1.12rem;
      }
    }
  }

  /* 导航栏字体 */
  .aboutus-page /deep/.el-tabs__item {
    font-size: 1.12rem !important;
  }

  /* 被选中导航栏字体颜色 */
  .aboutus-page /deep/ .el-tabs__item.is-active {
    color: #009bad;
  }

  /*被选中导航栏内容背景颜色 */
  .aboutus-page /deep/ .el-tabs__active-bar {
    background-color: #009bad;
  }

  /* 取消导航栏下长边框 */
  .aboutus-page /deep/ .el-tabs__nav-wrap::after {
    width: 0%;
  }

  .honor-box-img:nth-child(even) {
    margin: 2rem 0 !important;
  }

  @media (max-width: 1530px) {
    .aboutus-page .company .company-left {
      padding: 2rem !important;
    }

    .aboutus-page .company .company-right {
      width: 45%;
    }

    .aboutus-page .company .company-left .company-facilitator {
      margin: 2rem 0;
    }

    .aboutus-page .honor .honor-box {
      width: 100% !important;
    }

    .aboutus-page .certification .certification-box {
      width: 100%;
    }
  }
</style>